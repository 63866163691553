import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Select,
  Modal
} from 'antd';
import {
  ArrowLeftOutlined,
  ContactsOutlined,
  SaveOutlined,
  FileAddOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import { useQuery, useModels, useModel } from "../../../hooks";
import { ViewLoading } from '../../../components';
import StatusResponse from '../../../services/statusResponse';


const ConfiguracionForm = () => {

  const titulo = 'Configuración';
  const [form] = Form.useForm();
  const history = useHistory();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const [loading, setLoading] = useState(false)

  const { Option } = Select;

  const {
    model,
    modelLoading,
  } = useModel({
    name: 'configuracion',
    id: id,
  });

  const breadcrumbItems = [
    {
      name: "Configuración",
      to: '/gestion-general/configuracion/',
      icon: <ContactsOutlined />,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ? '/gestion-general/configuracion/editar?id=' + id :
        '/gestion-general/configuracion/nuevo',
      icon: <FileAddOutlined />,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/configuracion/'),
    icon: <ArrowLeftOutlined />,
    props: { disabled: false, type: 'primary', }
  };

  const onFinish = async (values) => {
    const { parametro, valor, comentario } = values; // Correct field names here
    let body = {
      parametro, // Map to the correct field name
      idConfiguracion: id,
      valor, // Map to the correct field name
      comentario, // Map to the correct field name
    };
    if (editing) {
      body.idConfiguracion = id;
    }
    try {
      setLoading(true);
      const res = await StatusResponse.post('configuracion/guardar', body);
      if (res) {
        // Handle response status codes and messages as needed
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>{arrayError.map((m, i) =>
                <span key={(i + 1)}> -{m} <br />
                </span>)
              }</div>
            )
          });
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: '20vh' },
          });
          //todo salió bien
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: '20vh' }
          });
          history.push('/gestion-general/configuracion');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = ({ values, errorFields, outOfDate }) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
        parametro: model?.parametro,
        valor: model?.valor,
        comentario: model?.comentario,
      })
      console.log(model?.parametro)
    };
    return () => mounted = false;
  }, [form, modelLoading, model])

  if (modelLoading) return (<ViewLoading />);

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >

      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
          <Col
            className="gutter-row"
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Parámetro"
              name="parametro"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input
                placeholder="Nombre del parámetro"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Valor"
              name="valor"
              rules={[{ required: true, message: 'Requerido' }]}
              hasFeedback
            >
              <Input
                placeholder="Agregar valor"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Comentario"
              name="comentario"
              // rules={[{ required: true, message: 'Requerido' }]}
              hasFeedback
            >
              <Input
                placeholder="Agregar comentario"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 5, offset: 19 }}
            lg={{ span: 5, offset: 19 }}
            xxl={{ span: 5, offset: 19 }}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined />}
              >Guardar</Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </DefaultLayout>
  );
};

export default ConfiguracionForm;
