import React, {useState, useEffect, useMemo} from 'react';
import {DefaultLayout} from '../../../components/layouts';
import {useHistory} from 'react-router-dom';
import {useModel, useModels, useQuery} from '../../../hooks';
import {Form, Row, Col, Button, Select, message, Modal} from 'antd';
import {UserOutlined, FileAddOutlined, ArrowLeftOutlined, SaveOutlined} from '@ant-design/icons';
import StatusResponse from '../../../services/statusResponse';
import { ViewLoading } from '../../../components';

const DiputadosDistritoForm = () => {

  const titulo = 'Diputado';
  const [form] = Form.useForm();
  const history = useHistory();

  const [loading, setLoading] = useState(false);

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;

  // distrito
  const [distritoSelect, setDistritoSelect] = useState([]);
  const [buscarDistritos, setbuscarDistritos] = useState("")
  const [requestDistritos, setRequestDistritos] = useState([]);
  
  // diputados
  const [diputadosSelect, setDiputadosSelect] = useState([]);
  const [buscarDiputados, setBuscarDiputados] = useState("");
  const [diputados, setDiputados] = useState([]);

  // partidos
  const [partidosSelect, setPartidosSelect] = useState([]);
  const [buscarPartidos, setBuscarPartidos] = useState("")
  const [requestPartidosPolitico, setRequestPartidosPolitico] = useState([]);

  // ModelRequest
  const [request, setRequest] = useState({})

  const diputadoDistritoParams = useMemo(() => ({
    name: 'diputado-distrito',
    id: id,
    expand: 'diputado,distrito,municipio'
  }),[id]);
  
  const requestDistritosParams = useMemo(() => ({
    name: 'distrito',
    limite: 25,
    extraParams: {
      buscar: buscarDistritos.length >= 1 ? buscarDistritos : ""
    }
  }),[buscarDistritos]);

  const requestPartidosParams = useMemo(() => ({
    name: 'partido',
    limite: 20,
    extraParams: {
      buscar: buscarPartidos.length >= 1 ? buscarPartidos : ""
    }
  }),[buscarPartidos]);

  const requestDiputadosParams = useMemo(() => ({
    name: 'usuario',
    expand: 'areas, cargo',
    extraParams: {
      diputados: 'diputados',
      buscar: buscarDiputados.length >= 3 ? buscarDiputados : ""
    },
    limite: 20
  }), [buscarDiputados]);

  const {
    model,
    modelLoading,
  } = useModel(request);

  const [
    distritos,
    distritosLoading
  ] = useModels(requestDistritos);

  const [
    usuarios,
    usuariosLoading, , ,
  ] = useModels(diputados);

  const [
    partidos,
    partidosLoading
  ] = useModels(requestPartidosPolitico);

  const breadcrumbItems = [
    {
      name: "Diputados Por Distrito",
      to: '/gestion-general/diputados-distrito',
      icon: <UserOutlined/>,
    },
    {
      name: editing ? "Diputado" : "Nuevo",
      to: editing ? '/gestion-general/diputados-distrito/editar?id=' + id
        : '/gestion-general/diputados-distrito/nuevo',
      icon: <FileAddOutlined/>,
    }
  ]

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/diputados-distrito/'),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const onFinish = async (values) => {  

    setLoading(true);

    let body = {
      ...values,
      id: id,
    }

    let _body = {
      idPartido: body?.partido,
      idUsuario: body?.diputado,
      idDistrito: body?.distrito
      
    }

    try {
      const res = await StatusResponse.post('diputado-distrito', _body)
      if(res) {
        if( res?.status === 400 && res?.errores !== null ) {
          const newArray = Object.values(res?.errores);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>{ newArray.map((m,i) => 
              <span key={(i+1)}> -{m} <br /> 
              </span> ) 
              }</div>
            )
          });
        } else if ( res?.status === 400 && res?.errores === null ) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: '20vh' },
          });
          //todo salió bien
        } else if ( res?.status === 200 ) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: '20vh' }
          });
          history.push('/gestion-general/diputados-distrito/');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ',e);
    } finally {
      setLoading(false);
    }

  }

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  }; 

  useEffect(() => {
    if(usuarios?.length > 0) {
      const arr = usuarios?.map(u => ({
        key: u?.idUsuario,
        value: u?.idUsuario,
        label: u?.nombreCompleto
      }));
      setDiputadosSelect(arr)
    }
  }, [usuarios]);

  useEffect(() => {
    if(partidos?.length > 0) {
      const arr = partidos?.map(p => ({
        key: p?.idPartido,
        value: p?.idPartido,
        label: `${p?.abreviatura} - ${p?.nombre}`
      }));
      setPartidosSelect(arr)
    }
  }, [partidos]);

  useEffect(() => {
    if(distritos?.length > 0) {
      const arr = distritos?.map(d => ({
        key: d?.idDistrito,
        value: d?.idDistrito,
        label: d?.nombre !=="---" ? `DISTRITO ${d?.nombre} - ${d?.descripcion}` : d?.descripcion
      }));
      setDistritoSelect(arr)
    }
  }, [distritos]);  

  useEffect(() => {
    setRequest(diputadoDistritoParams)
  }, [diputadoDistritoParams])
  

  useEffect(() => {
    setDiputados(requestDiputadosParams);
  }, [requestDiputadosParams])

  useEffect(() => {
    setRequestPartidosPolitico(requestPartidosParams)
  }, [requestPartidosParams])
  

  useEffect(() => {
    setRequestDistritos(requestDistritosParams);
  }, [requestDistritosParams])

  useEffect(() => {
    if(editing && model) {
      form.setFieldsValue({
        ...model,
        partido: model?.partidoPolitico?.abreviatura,
        diputado: model?.diputado?.nombreCompleto,
        distrito: model?.distrito?.descripcion
      })
    }
  }, [editing, form, model])

  if (modelLoading) return (<ViewLoading/>);

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 8, offset: 0}}
            lg={{span: 8, offset: 0}}
          >
            <Form.Item
              label="Partido"
              name="partido"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Selecciona un partido"
                style={{width: "100%"}}
                optionFilterProp="label"
                loading={partidosLoading}
                onSearch={setBuscarPartidos}
                filterOption={(inputValue, option) => {
                  const valor = inputValue.replaceAll(' ', '').toLowerCase();
                  return option.label.replaceAll(' ', '').toLowerCase().includes(valor);
                }}
                options={partidosSelect.length > 0 && partidosSelect}
              />
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 8, offset: 0}}
            lg={{span: 8, offset: 0}}
          >
            <Form.Item
              label="Distrito"
              name="distrito"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Select            
                allowClear
                showSearch
                placeholder="Selecciona un distrito"
                style={{width: "100%"}}
                optionFilterProp="label"
                loading={distritosLoading}
                onSearch={setbuscarDistritos}
                filterOption={(inputValue, option) => {
                  const valor = inputValue.replaceAll(' ', '').toLowerCase();
                  return option.label.replaceAll(' ', '').toLowerCase().includes(valor);
                }}
                options={distritoSelect.length > 0 && distritoSelect}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 8, offset: 0}}
            lg={{span: 8, offset: 0}}
          >
            <Form.Item
              label="Diputado"
              name="diputado"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Select
                allowClear
                showSearch
                placeholder="Selecciona un Diputado"
                style={{width: "100%"}}
                optionFilterProp="label"
                loading={usuariosLoading}
                onSearch={setBuscarDiputados}
                filterOption={(inputValue, option) => {
                  const valor = inputValue.replaceAll(' ', '').toLowerCase();
                  return option.label.replaceAll(' ', '').toLowerCase().includes(valor);
                }}
                options={diputadosSelect.length > 0 && diputadosSelect}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 5, offset: 19}}
            lg={{span: 5, offset: 19}}
            xxl={{span: 5, offset: 19}}
          >
            <Form.Item>
              <Button
                icon={<SaveOutlined/>}
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
              >Guardar</Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  )
}

export default DiputadosDistritoForm;