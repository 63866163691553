const { REACT_APP_API_URL: baseUrl } = process.env;
const localStorageKey = "usr_jwt";

const getCurrentToken = () => {
  return new Promise((resolve, reject) => {
    const jwt = localStorage.getItem(localStorageKey);
    if (!jwt) reject("No hay sesión.");
    resolve(jwt);
  });
};

const getHeaders = (token) => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: `Bearer ${token}`,
});

const getHeadersWithoutToken = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
});

const HttpService = {
  get: async (url, auth = true) => {
    let token = null;
    if (auth) token = await getCurrentToken();
    const response = await fetch(baseUrl+'/v1/'+ url, {
      method: "GET",
      headers: auth ? getHeaders(token) : getHeadersWithoutToken(),
    });

    return response.json();
  },
  post: async (url, data, auth = true) => {
    let token = null;
    if (auth) token = await getCurrentToken();
    const response = await fetch(baseUrl+'/v1/'+ url, {
      method: "POST",
      headers: auth ? getHeaders(token) : getHeadersWithoutToken(),
      body: JSON.stringify(data),
    });

    return response.json();
  },
};

export default HttpService;
