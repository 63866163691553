import React from "react";
import {Layout, Menu, Row, Col, Avatar, Tooltip} from "antd";
import {UserOutlined, LogoutOutlined, AuditOutlined} from "@ant-design/icons";
import {useAuth} from "../../hooks";
import {NavLink, useLocation, useHistory} from "react-router-dom";
import {dashboardRoutes} from "../../routers";
import {Permiso} from "../../utilities"
import "../../styles/DashboardLayout.less";
import {MdLogout} from "react-icons/md";

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

const rootSubmenuKeys = [""];

const DashboardLayout = ({children: PanelContent}) => {
  const [collapsed, setCollapsed] = React.useState(false);
  const [openKeys, setOpenKeys] = React.useState([""]);
  const [selectedKey, setSelectedKey] = React.useState("");
  const location = useLocation();
  const {user, userLoading, signOut} = useAuth();
  const history = useHistory();

  //console.log(user);

  React.useEffect(() => {
    let mounted = true;
    if (mounted) setCollapsed(false);
    return () => mounted = false;
  }, [])

  React.useEffect(() => {
    const flatter = (r) =>
      r?.routes
        ? [
          r,
          ...r?.routes
            .map((sub) => ({...sub, path: r.path + sub.path}))
            .flatMap(flatter),
        ]
        : r;
    const flatted = dashboardRoutes.flatMap(flatter);
    const paths = flatted.map((r) => r.path);
    const key = paths.find((path) => path === location.pathname);
    // TODO: revisar active cuando navegas hacia adentro.
    setSelectedKey(key);
    const tmpOpenKeys = flatted
      .filter(
        (r) => r.sidebar === "collapse" && location.pathname.includes(r.path)
      )
      .map((r) => "collapse-" + r.path);
    let _ret = collapsed ? [''] : tmpOpenKeys;
    setOpenKeys(_ret);
  }, [collapsed, location.pathname]);

  const onCollapse = () => {
    setCollapsed(c => !c);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const sidebarMapper = (route) => {
    if (route.sidebar === "single") {
      return (
        <Menu.Item
          key={route.path}
          onClick={() => setSelectedKey(route.path)}
          icon={route.icon}
          hidden={
            route.rol ? !route?.rol.includes(user?.rol) : (route.ver ? !user?.permisoExtra?.includes(route.ver) : false)
          }
        >
          <NavLink to={route.path}>{route.name}</NavLink>
        </Menu.Item>
      );
    } else if (route.sidebar === "collapse") {
      const innerMap = (r) => ({...r, path: route.path + r.path});
      const finalKey = "collapse-" + route.path;
      return (
        <SubMenu
          key={finalKey}
          icon={route.icon}
          title={route.name}
          hidden={
            route.rol ? !route?.rol.includes(user?.rol) : (route.ver ? !user?.permisoExtra?.includes(route.ver) : false)
          }
        >
          {route?.routes?.map(innerMap).map(sidebarMapper)}
        </SubMenu>
      );
    }
    return null;
  };

  if (!user && userLoading) return null;

  return (
    <Layout style={{minHeight: "100vh"}}>
      <Sider
        id="auth-sider"
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        theme="light"
        width={236}
      >
        <a href="/">
          <img src={collapsed ? "/logoCollapsed.png" : "/logo.png"} style={{padding: 10}} width="100%" alt="Logo"/>
        </a>
        <Menu
          mode="inline"
          openKeys={collapsed ? [] : openKeys}
          onOpenChange={onOpenChange}
          className="sider-menu"
          selectedKeys={selectedKey}
          style={{
            paddingBottom: 40,
            overflowY: 'overlay',
            overflowX: 'hidden',
            height: '80vh'
          }}
          inlineIndent={18}
        >
          {dashboardRoutes.map(sidebarMapper)}
          <Menu.Item
            key="single-/45"
            hidden={!Permiso(45)}
            icon={<AuditOutlined/>}
            onClick={() => window.location.href = 'https://sistema.declaranet.congresoson.gob.mx/'}
          >
            Declaranet
          </Menu.Item>
          <Menu.Item
            key="single-/0"
            icon={<LogoutOutlined/>}
            onClick={signOut}
          >
            Cerrar sesión
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout className="site-layout">
        <Header className="site-layout-background" style={{padding: 0, zIndex: 9}}>
          <Row justify="space-between" style={{padding: "0px 10px"}}>
            <Col>
              <span style={{fontSize: 10, color: "gray"}}> - </span>
            </Col>
            <Col>
              <Row>
                <Col style={{marginLeft: 10}}>{user?.nombreCompleto}</Col>
                <Col style={{marginLeft: 20}}>{user?.correo}</Col>
                <Col style={{marginLeft: 10}}>
                  <Avatar
                    style={{cursor: "pointer"}}
                    onClick={() => history.push("/perfil")}
                    size={42}
                    src={user?.urlFoto}
                    icon={<UserOutlined/>}
                  />
                </Col>
                <Col style={{marginLeft: 10}}>
                  <Tooltip
                    title={"Cerrar Sesión"}
                    placement="bottomRight"
                  >
                    <Avatar
                      style={{cursor: "pointer"}}
                      onClick={signOut}
                      shape="square"
                      size={42}
                      icon={<MdLogout/>}
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <Content style={{margin: "0 16px"}}>{PanelContent}</Content>
        <Footer>Sistema Integral de Documentación e Información del Congreso del Estado
          &#xa9; - v2.1.1 </Footer>
      </Layout>
    </Layout>
  );
};

export default DashboardLayout;