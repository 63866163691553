import React, {useCallback, useEffect, useMemo, useState} from 'react';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';
import {Modal, Table} from 'antd';
import {useHistory} from 'react-router-dom';
import {
  UserOutlined, 
  PlusOutlined, 
  EditOutlined, 
  DeleteOutlined,
  CloseCircleOutlined
} from '@ant-design/icons';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import { useModels } from '../../../hooks';
import StatusResponse from '../../../services/statusResponse';

const DiputadosDistrito = () => {

  const titulo = "Diputado Por Distrito";
  const url = '/gestion-general/diputados-distrito';
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [ limite, setLimite ] = useState(10);
  const [ pagina, setPagina ] = useState(0);
  const [request, setRequest] = useState({});

  const requestMemoParams = useMemo(() => ({
    name: 'diputado-distrito',
    ordenar: 'idDiputadoDistrito-desc',
    limite: limite,
    pagina: pagina
  }), [limite, pagina])

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels(request);

  const breadcrumbItems = [
    {
      name: "Diputado Por Distrito",
      icon: <UserOutlined/>,
      to: "/gestion-general/diputados-distrito"
    },
  ]

  const buttonData = {
    text: "Agregar nuevo",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const onSearch = async (search) => {
    setSearching(true);
    let params = {};
    if (search.length > 0) params = { ...params, buscar: search };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
    if (!modelsLoading) setSearching(false);
  }

  const onSetPageCallback = useCallback(
    async ( page, size ) => {
      setPagina(page);
      setLimite(size);
      await refreshModels ( true,  {
        ordenar: 'id-desc',
        pagina: page,
        limite: size,
      })
    }, [ refreshModels ]
  );

  const configPagination = useMemo( () => {
    if( modelsPage ) {
    let size = limite;
    
    return {        
      total: modelsPage.total,
      pageSize: modelsPage.limite,
      onShowSizeChange: ( _, newSize)  => ( size = newSize ),
      onChange: async (v) => await onSetPageCallback(v, size),
      showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} distritos.`,
      locale: { items_per_page: '/ página' },
      pageSizeOptions: [10, 20, 30].filter( val => val <= modelsPage.total ),
      showSizeChanger: true
    }}
    return null;
  }, [ onSetPageCallback, limite, modelsPage ] );

  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar el Distrito "${valor?.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("distrito",{ id: valor.id});
        return new Promise((resolve, reject)=>{
          try {
            if (valor.id > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'id-asc',
                  pagina: 1,
                  limite: limite
                });
                resolve();
              } else if ( res?.status === 400 ) {
                Modal.error({
                  title: "Error: no se logro eliminar Distrito",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const defaultText = prop => prop || <span style={{color: '#c7c3c3'}}>---</span>;

  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={"Acción"}
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined/>,
              onClick: () => history.push(`${url}/editar?id=${item?.idDiputadoDistrito}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              onClick: () => modalEliminar(item),
              styleProps: {color: '#ff4d4f'}
            }
          ]}
        />
      )
    },
    {
      title: 'Partido',
      dataIndex: 'partido',
      key: 'partido',
      render: (_, item) => item?.partidoPolitico?.abreviatura ? `${item?.partidoPolitico?.abreviatura} - ${item?.partidoPolitico?.nombre}` : defaultText
    },
    {
      title: 'Distrito',
      dataIndex: 'distrito',
      key: 'distrito',
      render: (_, item) => item?.distrito?.nombre!=="---" ? `Distrito ${item?.distrito?.nombre}` : item?.distrito?.descripcion
    },
    {
      title: 'Cabecera',
      dataIndex: 'distrito',
      key: 'distrito',
      render: (_, item) => item?.distrito?.descripcion ? item?.distrito?.descripcion : defaultText
    },
    {
      title: 'Diputado',
      dataIndex: 'diputado',
      key: 'diputado',
      render: (_, item) => item?.diputado?.nombreCompleto ? item?.diputado?.nombreCompleto : defaultText
    }
  ];

  useEffect(() => {
    setRequest(requestMemoParams)
  }, [requestMemoParams])
  

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      searchLoading={searching}
      onSearchClicked={onSearch}
      emptyText='Aún no hay diputados registrados.'
      children={
        <>
          <Table
            dataSource={models}
            rowKey='id'
            columns={columns}
            loading={modelsLoading}
            pagination={configPagination}
            size='small'
            scroll={{x: 1000}}
          />
        </>
      }
    />
  );
}

export default DiputadosDistrito;