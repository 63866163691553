import React, { useState } from "react";
import { Button, Form } from "antd";
import EditorToolbar, { modules, formats } from "./EditorToolbar";

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


// TODO!: UNA VEZ TERMINADO, DE USAR EL EJEMPLO, ELIMINAR ESTE ARCHIVO Y LA RUTA EN PublicRoutes.js

const Editor = () => {

  const [value, setValue] = useState('');

  const onFinish = () => {
    console.log(value);
  };

  return (
    <>
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        autoComplete="off"
      >

        <EditorToolbar />
        <ReactQuill 
          style={{ display:'block', width:'100%', height:300 }}
          theme="snow" 
          value={value} 
          onChange={setValue}
          modules={modules}
          formats={formats}
        />

      
        
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default Editor;
