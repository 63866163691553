import React from 'react';
import {Col, Row, Table} from 'antd';
import { useModels, useSortColumns, usePagination } from '../hooks';
import PropTypes from "prop-types";

const Tabla = ({
  nameURL,
  expand = '',
  extraParams = null,
  columns,
  order,
  innerRef,
  rowKey,
  scrollX = '80vw',
  emptyText = 'No existen datos',
  ...props
}) => {

  const [columnsData, setColumnsData] = React.useState([]);
  const [request, setRequest] = React.useState({});

  const {
    limit,
    page,
    configPagination,
    setTotal,
  } = usePagination();

  const { sortValue, columnsContent } = useSortColumns({ columnsData, order: order || 'id-desc' });

  const requestParams = React.useMemo(() => {
    let obj = {
      name: nameURL,
      ordenar: sortValue,
      expand: expand || '',
      limite: limit,
      pagina: page
    }

    if (extraParams) {
      obj.extraParams = extraParams
    }
    
    return obj
  }, [expand, extraParams, limit, nameURL, page, sortValue]);

  const [
    models,
    modelsLoading, ,
    modelsPage, 
    refresh
  ] = useModels(request);

  React.useEffect(() => {
    setRequest(requestParams);
    return () => setRequest({});
  }, [requestParams]);

  React.useEffect(() => {
    setColumnsData(columns);
  }, [columns]);

  React.useEffect(() => {
    if (modelsPage) {
      setTotal(modelsPage?.total);
    }
  }, [modelsPage, setTotal]);

  if (innerRef) {
    innerRef.current = {
      refresh
    }
  }

  let localeTable = {
    emptyText: <Row>
      <Col span={24}><img src="/logoCollapsed.png" alt="" style={{width: '10%'}}/></Col>
      <Col span={24} style={{marginTop:'2%'}}>{emptyText}</Col>
    </Row>
  };

  return (
    <Table
      {...props}
      dataSource={models}
      columns={columnsContent}
      rowKey={rowKey}
      loading={modelsLoading}
      pagination={configPagination}
      style={{ whiteSpace: 'pre' }}
      scroll={{ x: scrollX }}
      locale={localeTable}
      size="small"
    />
  )
}

Tabla.propTypes = {
  nameURL: PropTypes.string.isRequired,
  columns: PropTypes.array.isRequired,
  expand: PropTypes.string,
  extraParams: PropTypes.object,
  order: PropTypes.string,
  scrollX: PropTypes.string,
}

export default Tabla