import React, {useState, useMemo, useCallback} from 'react'

import {
  Table,
} from 'antd';

import {
  DeleteOutlined,
  EditOutlined,
  BankOutlined,
  PlusOutlined
} from '@ant-design/icons';

import {useHistory} from 'react-router-dom';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import {useModels} from "../../../hooks";
import {FaParking} from "react-icons/fa";

const PartidoPoliticoListado = () => {

  const titulo = "Partidos Políticos";
  const url = '/gestion-general/partidos-politicos';
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [ limite, setLimite ] = useState(10);
  
  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'partido',
    ordenar:'orden'
  });

  const breadcrumbItems = [
    {
      name: titulo,
      to: url,
      icon: <FaParking/>,
    },
  ];

  const buttonData = {
    text: "Agregar Partido",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  /*const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar la Dependencia "${valor?.titulo}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("expediente/eliminar",{ idDependencia: valor.idDependencia});
        return new Promise((resolve, reject)=>{
          try {
            if (valor.idDependencia > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'idDependencia-asc',
                  pagina: 1,
                  limite: limite
                });
                resolve();
              } else if ( res?.status === 400 ) {
                Modal.error({
                  title: "Error: no se logro eliminar la Dependencia",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };
*/

  const defaultText = (prop, item) => {
    return item ? <span className="link-pointer" onClick={() => {
        history.push(`${url}/editar?id=${item.idPartido}`)
      }}>{prop}</span>
      : <span style={{color: '#c7c3c3'}}>---</span>
  }

  const defaultImage = (prop, item) => {
    return item ? <span className="link-pointer" onClick={() => {
        history.push(`${url}/editar?id=${item.idPartido}`)
      }}><img src={prop}/></span>
      : <span style={{color: '#c7c3c3'}}>---</span>
  }

  const columns = [

    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item)=>(
        <ActionsButton
          key={item.id}
          text={""}
          options={[
            {
              name:'Editar',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idPartido}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined />,
              styleProps: { color: '#ff4d4f' }
            }
          ]}
        />
      )
    },
    {
      title: 'Imagen',
      dataIndex: 'logo',
      key: 'logo',
      render: defaultImage,
    },
    {
      title: 'Abreviatura',
      dataIndex: 'abreviatura',
      key: 'abreviatura',
      render: defaultText,
    },
    {
      title: 'Título',
      dataIndex: 'nombre',
      key: 'nombre',
      render: defaultText,
    },
  ];

  const onSearch = async (search) => {
    setSearching(true);
    let params = {};
    if (search.length > 0) params = {...params, buscar: search };
    params = {...params, pagina: 0};
    await refreshModels(true, params);
    if(!modelsLoading) setSearching(false);
  };

  const onSetPageCallback = useCallback(
    async ( page, size ) => {
      setLimite(size);
      await refreshModels ( true,  {
        ordenar: 'idPartido',
        pagina: page,
        limite: size,
      })
    }, [ refreshModels ]
  );

  const configPagination = useMemo( () => {
    if( modelsPage ) {
    let size = limite;
    
    return {        
      total: modelsPage.total,
      pageSize: modelsPage.limite,
      onShowSizeChange: ( _, newSize)  => ( size = newSize ),
      onChange: async (v) => await onSetPageCallback(v, size),
      showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} Partidos Politicos.`,
      locale: { items_per_page: '/ página' },
      pageSizeOptions: [10, 20, 30].filter( val => val <= modelsPage.total ),
      showSizeChanger: true
    }}
    return null;
  }, [ onSetPageCallback, limite, modelsPage ] );

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      searchLoading={searching}
      onSearchClicked={onSearch}
      emptyText='Aún no hay áreas registrados.'
      children={
        <>
          <Table
            dataSource={models}
            rowKey="idPartido"
            loading={modelsLoading}
            pagination={configPagination}
            columns={columns}
            size='small'
            scroll={{ x: 1000 }}
          />
        </>
      }
    />
  )
}

export default PartidoPoliticoListado
//Avenida Niños Héroes número 183 esquina con calle Pino Suarez. Colonia Centro. C.P. 83000. Hermosillo, Sonora.