import React, {useState, useMemo, useCallback} from "react";
import {
  Table,
  Modal,
  message, Form, Row, Col, Input, Space, Tooltip, Button, Upload,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  TeamOutlined,
  PlusOutlined,
  SearchOutlined,
  ClearOutlined,
  ExclamationCircleOutlined, CloudUploadOutlined,
} from "@ant-design/icons";
import DefaultLayout from '../../components/layouts/DefaultLayout';
import { useModels} from "../../hooks";
import ActionsButton from '../../components/actionsbutton/ActionsButton';
import {useHistory} from 'react-router-dom';
import StatusResponse from '../../services/statusResponse';
import {ViewLoading} from "../../components";

const {REACT_APP_API_URL: baseUrl} = process.env;

const Usuarios = () => {

  const _baseUrl = baseUrl.replace("v1/", "");
  const titulo = "Usuarios";
  const ordenar = 'nombre-asc';
  const localStorageKey = "usr_jwt";
  const history = useHistory();
  const {confirm} = Modal;
  const [form] = Form.useForm();

  const defaultText = prop => prop || <span style={{color: '#c7c3c3'}}>---</span>;
  const [limite, setLimite] = useState(10);
  const [listaArchivo, setListaArchivo] = useState([]);

  const [buscar, setBuscar] = useState(undefined);
  const [inicio, setInicio] = useState(undefined);
  const [fin, setFin] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const [archivoCargando, setArchivoCargando] = useState(false);

  const extraParamsMemo = useMemo(() => {
    let params = {};
    if (buscar && buscar.trim() !== '') {
      params.buscar = buscar.trim();
    }

    if (inicio && fin) {
      params.inicio = inicio.format('YYYY-MM-DD');
      params.fin = fin.format('YYYY-MM-DD');
    }
    return params

  }, [buscar, fin, inicio]);

  const breadcrumbItems = [
    {
      name: titulo,
      to: '/gestion-general/usuarios/',
      icon: <TeamOutlined/>,
    },
  ];

  const [
    models,
    modelsLoading, ,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'usuario',
    extraParams: extraParamsMemo,
    ordenar: ordenar,
    limite: limite
  });


  const columns = [
    {
      title: 'Acciones',
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.idUsuario}
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined/>,
              onClick: () => history.push(`/gestion-general/usuarios/editar?id=${item.idUsuario}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined/>,
              onClick: () => eliminarModal(item),
              styleProps: {color: '#ff4d4f'}
            }
          ]}
        />
      )
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      render: (_, item) => <a onClick={() => history.push(`/gestion-general/usuarios/editar?id=${item.idUsuario}`)}>
        {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
      </a>
    },
    {
      title: 'Correo',
      dataIndex: 'correo',
      key: 'correo',
      render: (_, item) => <a onClick={() => history.push(`/gestion-general/usuarios/editar?id=${item.idUsuario}`)}>
        {item?.correo}
      </a>
    },
    {
      title: 'Télefono',
      dataIndex: 'telefono',
      key: 'telefono',
      render: defaultText,
    },
  ];

  const onSetPageCallback = useCallback(
    async (page, size) => {
      setLimite(size);
      await refreshModels(true, {
        ordenar: ordenar,
        pagina: page,
        limite: size,
      })
    }, [refreshModels]
  );

  const onChangeFile = ({fileList: newFileList}) => {
    subirArchivo(newFileList, '/v1/usuario/subir-excel');
  };

  const getCurrentToken = () => {
    return new Promise((resolve, reject) => {
      const jwt = localStorage.getItem(localStorageKey);
      if (!jwt) reject("No hay sesión.");
      resolve(jwt);
    });
  };

  const subirArchivo = async (file, ruta) => {
    setLoading(true);
    try {

      let _archivo = file[0].originFileObj;

      console.log(_archivo);

      if (!_archivo) {
        return
      }

      const form = new FormData();
      form.append('archivo', _archivo);

      const token = await getCurrentToken();

      const response = await fetch(_baseUrl + ruta, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      });

      return await response.json();
    } catch (error) {
      console.log('error al cargar archivo: ', error);
    } finally {
      setArchivoCargando(false);
      refreshModels();
      setLoading(false);
    }
  };

  const configPagination = useMemo(() => {
    if (modelsPage) {
      let size = limite;
      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: (_, newSize) => (size = newSize),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} Usuarios.`,
        locale: {items_per_page: '/ página'},
        pageSizeOptions: [10, 20, 30].filter(val => val <= modelsPage.total),
        showSizeChanger: true
      }
    }
    return null;
  }, [onSetPageCallback, limite, modelsPage]);


  const SearchForm = () => {

    const onFinishButton = async values => {
      let params = {}
      const {buscar, rango} = form.getFieldsValue(true);
      if (buscar && buscar.trim() !== '') {
        setBuscar(buscar.trim());
        params.buscar = buscar.trim();
      }

    }

    const actualizarFechas = async (val) => {
      console.log("" + val);
      const res = await StatusResponse.post("usuario/actualizar-fecha-nacimiento");
      console.log(res);
    }

    return (
      <Form
        form={form}
        layout="vertical"
      >
        <Row gutter={16}>
          <Col xs={24} sm={24} md={10} lg={10} xl={10} xxl={10}>
            <Form.Item
              label="Buscar: "
              name="buscar"
            >
              <Input
                disabled={modelsLoading}
                placeholder="NOMBRE, CORREO, RFC, ETC..."
                defaultValue={buscar}
                value={buscar}
              />
            </Form.Item>
          </Col>

          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4}>
            <Space
              justify="space-between"
              style={{marginTop: '.5rem'}}
            >
              <Tooltip title="Buscar">
                <Button
                  icon={<SearchOutlined/>}
                  style={{marginTop: '1.5rem'}}
                  shape="circle"
                  type="primary"
                  // htmlType="submit"
                  loading={modelsLoading}
                  disabled={modelsLoading}
                  onClick={onFinishButton}
                />
              </Tooltip>

              <Tooltip title="Limpiar">
                <Button
                  icon={<ClearOutlined/>}
                  style={{marginTop: '1.5rem'}}
                  shape="circle"
                  type="default"
                  disabled={modelsLoading}
                  onClick={() => {
                    form.resetFields();
                    setBuscar('');
                    setInicio(null);
                    setFin(null);
                    refreshModels()
                  }}
                />
              </Tooltip>

              <Tooltip title="Subir Excel">
                <Upload
                  beforeUpload={() => false}
                  multiple={true}
                  fileList={listaArchivo}
                  maxCount={1}
                  onChange={onChangeFile}
                  accept=".xls,.xlsx,.csv"
                >
                  <Button
                    icon={<CloudUploadOutlined/>}
                    shape="circle"
                    style={{marginTop: '1.5rem', background: '#1f6e43', color: '#ffffff'}}
                  />
                </Upload>
              </Tooltip>

              <Tooltip title="Actualizar fecha nacimiento">
                <Button
                  icon={<CloudUploadOutlined/>}
                  shape="circle"
                  style={{marginTop: '1.5rem', background: '#1f6e43', color: '#ffffff'}}
                  onClick={(val) => actualizarFechas(val)}
                />
              </Tooltip>
            </Space>
          </Col>


          <Col xs={24} sm={24} md={4} lg={4} xl={4} xxl={4} offset={2}>
            <Form.Item
              label="&nbsp;"
            >
              <Button
                block
                type="primary"
                icon={<PlusOutlined/>}
                onClick={() => history.push('/gestion-general/usuarios/nuevo')}
              >
                Agregar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    )
  };

  if (loading) return (<ViewLoading/>);

  const eliminarModal = (item) => {
    confirm({
      title: `¿Estás seguro de eliminar el Usuario ${item.nombreCompleto}?`,
      icon: <ExclamationCircleOutlined/>,
      okText: 'Si, Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        try {
          if (item.idUsuario !== '') {
            const res = await StatusResponse.delete('/v1/usuario/eliminar', {idUsuario: item.idUsuario});
            if (res) {
              if (res?.status === 400 && res?.errores !== null) {
                const arrayError = Object.values(res?.errores);
                Modal.error({
                  title: res?.mensaje,
                  content: (
                    <div>{arrayError.map((m, i) => <span key={(i + 1)}> - {m} <br/></span>)}</div>
                  )
                });
                console.log(arrayError)
              } else if (res?.status === 400 && res?.errores === null) {
                message.error({
                  content: res?.mensaje,
                  style: {marginTop: '20vh'},
                });
              } else if (res?.status === 200) {
                message.success({
                  content: res?.mensaje,
                  style: {marginTop: '20vh'},
                });
              }
              ;
            }
            ;
            refreshModels();
          }
        } catch (error) {
          console.log('Error al eliminar: ', error);
        }
      },
      onCancel() {
      },
    });
  }

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      withSearchButton={false}
      children={
        <>
          <SearchForm/>
          <Table
            dataSource={models}
            columns={columns}
            loading={modelsLoading}
            pagination={configPagination}
            rowKey={'idUsuario'}
            size='small'
            scroll={{x: 1000}}
          />
        </>
      }
    />
  );
}

export default Usuarios;