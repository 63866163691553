import { Modal, notification } from "antd";
import { capitalizeFirst } from "./capitalizeFirst";
import { modalDelete } from "./modalDelete";
import { inventarioConcentracion } from "./inventarioConcentracion";
import { reporteSubFondoArea } from "./reporteSubFondoArea";
import { reporteExpediente } from "./reporteExpediente";
import { Respuestas } from "./Respuestas";
import { ValidarCurp } from "./ValidarCurp";
import { QuitarSignos } from "./QuitarSignos";
import { AgregarFaltantes } from "./AgregarFaltantes";
import { ValidarRfc } from "./ValidarRfc";
import { ValidarCorreo } from "./ValidarCorreo";
import { ValidarTelefono } from "./ValidarTelefono";
import { ValidarContrasena } from "./ValidarContrasena";
import { roles } from "./roles";
import { estatusExpediente } from "./estatusExpediente";
import { useAuth } from "../hooks";
import { InformacionArchivos } from "./InformacionArchivos";
import React from "react";

const maskCurrency = (value) => {
  return value?.toLocaleString("es-MX", { minimumFractionDigits: 2 });
};

const messageOk = (message) => {
  let secondsToGo = 1;
  const modal = Modal.success({
    title: message,
    cancelButtonProps: { style: { display: "none" } },
    okButtonProps: { style: { display: "none" } },
  });
  const timer = setInterval(() => {
    secondsToGo -= 1;
  }, 1000);
  setTimeout(() => {
    clearInterval(timer);
    modal.destroy();
  }, secondsToGo * 1000);
};

const messageError = (message) => {
  Modal.error({
    title: "Error",
    content: message,
  });
};

const formatoPesos = (number = 0) => {
  const exp = /(\d)(?=(\d{3})+(?!\d))/g;
  const rep = "$1,";
  return number?.toString().replace(exp, rep);
};

const trimestres = [
  { key: 1, label: "Primer Trimestre", value: "enero,febrero,marzo" },
  { key: 2, label: "Segundo Trimestre", value: "abril,mayo,junio" },
  { key: 3, label: "Tercer Trimestre", value: "julio,agosto,septiembre" },
  { key: 4, label: "Cuarto Trimestre", value: "octubre,noviembre,diciembre" },
];

const regimenFiscal = [
  {
    key: 601,
    label: "601 - General de Ley Personas Morales",
    value: "601 - General de Ley Personas Morales",
  },
  {
    key: 603,
    label: "603 - Personas Morales con Fines no Lucrativos",
    value: "603 - Personas Morales con Fines no Lucrativos",
  },
  {
    key: 605,
    label: "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
    value: "605 - Sueldos y Salarios e Ingresos Asimilados a Salarios",
  },
  { key: 606, label: "606 - Arrendamiento", value: "606 - Arrendamiento" },
  {
    key: 607,
    label: "607 - Régimen de Enajenación o Adquisición de Bienes",
    value: "607 - Régimen de Enajenación o Adquisición de Bienes",
  },
  { key: 608, label: "608 - Demás ingresos", value: "608 - Demás ingresos" },
  { key: 609, label: "609 - Consolidación", value: "609 - Consolidación" },
  {
    key: 610,
    label:
      "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
    value:
      "610 - Residentes en el Extranjero sin Establecimiento Permanente en México",
  },
  {
    key: 611,
    label: "611 - Ingresos por Dividendos (socios y accionistas)",
    value: "611 - Ingresos por Dividendos (socios y accionistas)",
  },
  {
    key: 612,
    label:
      "612 - Personas Físicas con Actividades Empresariales y Profesionales",
    value:
      "612 - Personas Físicas con Actividades Empresariales y Profesionales",
  },
  {
    key: 614,
    label: "614 - Ingresos por intereses",
    value: "614 - Ingresos por intereses",
  },
  {
    key: 615,
    label: "615 - Régimen de los ingresos por obtención de premios",
    value: "615 - Régimen de los ingresos por obtención de premios",
  },
  {
    key: 616,
    label: "616 - Sin obligaciones fiscales",
    value: "616 - Sin obligaciones fiscales",
  },
  {
    key: 620,
    label:
      "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
    value:
      "620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos",
  },
  {
    key: 621,
    label: "621 - Incorporación Fiscal",
    value: "621 - Incorporación Fiscal",
  },
  {
    key: 622,
    label: "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
    value: "622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras",
  },
  {
    key: 623,
    label: "623 - Opcional para Grupos de Sociedades",
    value: "623 - Opcional para Grupos de Sociedades",
  },
  { key: 624, label: "624 - Coordinados", value: "624 - Coordinados" },
  {
    key: 625,
    label:
      "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
    value:
      "625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas",
  },
  {
    key: 626,
    label: "626 - Régimen Simplificado de Confianza",
    value: "626 - Régimen Simplificado de Confianza",
  },
  { key: 628, label: "628 - Hidrocarburos", value: "628 - Hidrocarburos" },
  {
    key: 629,
    label:
      "629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
    value:
      "629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales",
  },
  {
    key: 630,
    label: "630 - Enajenación de acciones en bolsa de valores",
    value: "630 - Enajenación de acciones en bolsa de valores",
  },
];

const Permiso = (value) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { user } = useAuth();
  let _permiso = false;
  _permiso = user?.permisoExtra.includes(45);
  return _permiso;
};

function eliminarObjeto(arr, key) {
  const obj = arr.findIndex((obj) => obj[key] === key);
  console.log(obj, key, obj[key], arr);
  if (obj > -1) {
    arr.splice(obj, 1);
  }
  return arr;
}

const GetMesTexto = (number, corto = false) => {
  let mes = "";
  let mesCorto = "";
  let numero = parseInt(number);

  if (numero === 1) {
    mes = "Enero";
    mesCorto = "Ene";
  }
  if (numero === 2) {
    mes = "Febrero";
    mesCorto = "Feb";
  }
  if (numero === 3) {
    mes = "Marzo";
    mesCorto = "Mar";
  }
  if (numero === 4) {
    mes = "Abril";
    mesCorto = "Abr";
  }
  if (numero === 5) {
    mes = "Mayo";
    mesCorto = "May";
  }
  if (numero === 6) {
    mes = "Junio";
    mesCorto = "Jun";
  }
  if (numero === 7) {
    mes = "Julio";
    mesCorto = "Jul";
  }
  if (numero === 8) {
    mes = "Agosto";
    mesCorto = "Ago";
  }
  if (numero === 9) {
    mes = "Septiembre";
    mesCorto = "Sep";
  }
  if (numero === 10) {
    mes = "Octubre";
    mesCorto = "Oct";
  }
  if (numero === 11) {
    mes = "Noviembre";
    mesCorto = "Nov";
  }
  if (numero === 12) {
    mes = "Diciembre";
    mesCorto = "Dic";
  }

  if (corto) return mesCorto;
  else return mes;
};

const meses = [
  { key: 1, value: 1, label: "Enero" },
  { key: 2, value: 2, label: "Febrero" },
  { key: 3, value: 3, label: "Marzo" },
  { key: 4, value: 4, label: "Abril" },
  { key: 5, value: 5, label: "Mayo" },
  { key: 6, value: 6, label: "Junio" },
  { key: 7, value: 7, label: "Julio" },
  { key: 8, value: 8, label: "Agosto" },
  { key: 9, value: 9, label: "Septiembre" },
  { key: 10, value: 10, label: "Octubre" },
  { key: 11, value: 11, label: "Noviembre" },
  { key: 12, value: 12, label: "Diciembre" },
];

const Anios = () => {
  let _anios = [];
  let anioActual = parseInt(new Date().getFullYear());
  let _anioLegislatura = parseInt(new Date().getFullYear()) - 5;

  for (let i = anioActual; i >= _anioLegislatura; i--) {
    _anios.push({ key: i, value: i, label: i });
  }
  return _anios;
};

const respuestas = (res) => {
  if (!res) return "Error en respuesta";

  if (res?.status >= 400 && res?.status < 499 && res?.errores !== null) {
    const errores = Object.values(res?.errores);
    notification.error({
      message: "Atención",
      description: errores.map((e, i) => (
        <React.Fragment key={`${i}-error`}>
          <span>- {e}</span>
          <br />
        </React.Fragment>
      )),
      placement: "bottomRight",
    });
  } else if (res?.status >= 400 && res?.status < 499 && res?.errores === null) {
    notification.error({
      message: "Atención",
      description: "Hubo un problema del lado del servidor.",
      placement: "bottomRight",
    });
  } else if (res?.status >= 200 && res?.status < 399) {
    notification.success({
      message: "¡Éxito!",
      description: `${res?.mensaje}`,
      placement: "bottomRight",
    });
  }
};

export {
  eliminarObjeto,
  QuitarSignos,
  capitalizeFirst,
  maskCurrency,
  messageOk,
  formatoPesos,
  messageError,
  modalDelete,
  AgregarFaltantes,
  respuestas,
  Respuestas,
  inventarioConcentracion,
  reporteExpediente,
  reporteSubFondoArea,
  ValidarCurp,
  ValidarRfc,
  ValidarTelefono,
  ValidarCorreo,
  Permiso,
  ValidarContrasena,
  GetMesTexto,
  Anios,
  roles,
  regimenFiscal,
  trimestres,
  estatusExpediente,
  meses,
  InformacionArchivos,
};
