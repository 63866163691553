import React, {useEffect, useState} from "react";
import {
  Col,
  Row,
  Form,
  Modal,
  Input,
  Select,
  Button,
  message,
  InputNumber,
  DatePicker,
  Divider,
  Checkbox,
  Popover,
} from "antd";
import {
  EyeTwoTone,
  TeamOutlined,
  SaveOutlined,
  ArrowLeftOutlined,
  CloseCircleOutlined,
  EyeInvisibleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import {useHistory} from "react-router-dom";
import StatusResponse from "../../services/statusResponse";
import {ViewLoading} from "../../components";
import {DefaultLayout} from "../../components/layouts";
import {useAuth, useModel, useModels, useQuery} from "../../hooks";
import Title from "antd/lib/typography/Title";
import {Respuestas, roles, ValidarContrasena} from "../../utilities";
import moment from "moment";

const UsuarioForm = () => {
  const titulo = "Usuarios";
  const [form] = Form.useForm();
  const history = useHistory();
  const {user} = useAuth();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const {confirm} = Modal;

  const [permisosUsuario, setPermisosUsuario] = useState([]);

  // Usuario
  const {
    model,
    modelLoading
  } = useModel({
    name: "usuario",
    expand: "permisos, areas, cargo, departamento, subFondoAreas",
    id: id,
    rol: 'DIPUTADO'
  });

  // Cargo
  const [
    cargos,
    cargosLoading, , ,
    refreshCargos
  ] = useModels({
    name: "cargo",
    ordenar: "nombre",
    expand: "area",
  });

  // Cargo
  const [
    departamentos,
    departamentosLoading, , ,
    departamentosRefresh
  ] = useModels({
    name: "departamento",
    ordenar: 'titulo'
  });

  const [
    permisos,
  ] = useModels({
    name: "permiso",
    expand: "permisoModulo",
    limite: 200,
  });

  const [
    permisoModulo,
  ] = useModels({
    name: "permiso-modulo",
  });

  const breadcrumbItems = [
    {
      name: titulo,
      to: "/gestion-general/usuarios/",
      icon: <TeamOutlined/>,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ? "/gestion-general/usuarios/editar?id=" + id
        : "/gestion-general/usuarios/nuevo",
      icon: <TeamOutlined/>,
    },
  ];

  useEffect(() => {
    form.setFieldsValue({
      telefono: model?.telefono ? model?.telefono : "6622596700",
      fechaIngreso: model?.fechaIngreso ? moment(model?.fechaIngreso) : null,
      fechaNacimiento: model?.fechaNacimiento ? moment(model?.fechaNacimiento) : null,
    });

    departamentos.push(model?.departamento)
  }, [model]);


  const onFinish = async (values) => {
    const {telefono, celular, extension} = values;

    const body = {
      ...values,
      idUsuario: id,
      telefono: telefono ? `${telefono}` : null,
      celular: celular ? `${celular}` : null,
      extension: extension ? `${extension}` : null,
    };

    try {
      const res = await StatusResponse.post("usuario/guardar", body);
      if (Respuestas(res)) {
        history.push("/gestion-general/usuarios/");
      }
    } catch (e) {
      console.log("Error al guardar: ", e);
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning("Error al guardar: datos incompletos.");
    console.log(values, errorFields, outOfDate);
  };

  const buttonData = {
    text: "Volver",
    to: () => history.push("/gestion-general/usuarios/"),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: "primary"},
  };

  const reglasCurp = [
    {
      validator: (_, value) =>
        value && value.length >= 18 && value.length <= 18
          ? Promise.resolve()
          : Promise.reject(new Error("el CURP es de 18 caracteres")),
    },
  ];

  const reglasRfc = [
    {
      validator: (_, value) =>
        value && value.length >= 10 && value.length <= 13
          ? Promise.resolve()
          : Promise.reject(new Error("el RFC es de 13 caracteres")),
    },
  ];

  const reglasTelefono = [
    {
      validator: (_, value) =>
        value && String(value).length > 9 && String(value).length < 11
          ? Promise.resolve()
          : Promise.reject(new Error("el Télefono no es de 10 caracteres ")),
    },
  ];

  const reglasCorreo = [
    {
      validator: (_, value) =>
        value && value.includes("@")
          ? Promise.resolve()
          : Promise.reject(new Error("debe de ser un Correo Electrónico")),
    },
  ];

  const chequearModulo = (e) => {
    let _permiso = e.target.value.split("-");
    let _idPermisoModulo = parseInt(_permiso[1]);
    const permisosForm = []
    const _permisos = form.getFieldValue('permisos');

    for (let i = 0; i < _permisos?.length; i++) {
      if (!permisosForm.includes(_permisos[i])) {
        permisosForm.push(_permisos[i])
      }
    }

    for (let i = 0; i < permisos?.length; i++) {
      if (_idPermisoModulo !== permisos[i]?.idPermisoModulo) {
        continue
      }
      if (e.target.checked && !permisosForm.includes(permisos[i]?.idPermiso)) {
        permisosForm.push(permisos[i]?.idPermiso)
      }
      if (!e.target.checked && permisosForm.includes(permisos[i]?.idPermiso)) {
        permisosForm.splice(permisosForm.indexOf(permisos[i]?.idPermiso), 1)
      }
    }

    if (e.target.checked) {
      permisosForm.push(e.target.value)
    } else {
      permisosForm.splice(permisosForm.indexOf(e.target.value), 1)
    }

    setPermisosUsuario(permisosForm)
  };

  const buscarCargos = async (value) => {
    if (value?.length > 0) {
      await refreshCargos(true, {
        buscar: value
      })
    }
  };

  const buscarDepartamento = async (value) => {
    if (value?.length > 0) {
      await departamentosRefresh(true, {
        buscar: value
      })
    }
  };

  const eliminarArea = (id, nombre) => {
    confirm({
      title: `¿Estás seguro de eliminar el área ${nombre}?`,
      icon: <ExclamationCircleOutlined/>,
      okText: 'Si, Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        try {
          const res = await StatusResponse.delete('/v1/usuario/eliminar-area', {
            idArea: id,
            idUsuario: model?.idUsuario
          });

          if (Respuestas(res))
            window.location.reload();

        } catch (error) {
          console.log('Error al eliminar: ', error);
        }
      },
      onCancel() {
      },
    });

  }

  const eliminarFondoAreas = (id, nombre) => {
    confirm({
      title: `¿Estás seguro de eliminar el área ${nombre}?`,
      icon: <ExclamationCircleOutlined/>,
      okText: 'Si, Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk: async () => {
        try {
          const res = await StatusResponse.delete('/v1/usuario/eliminar-sub-fondo-area', {
            idSubFondoArea: id,
            idUsuario: model?.idUsuario
          });
          if (Respuestas(res))
            window.location.reload();
        } catch (error) {
          console.log('Error al eliminar: ', error);
        }
      },
      onCancel() {
      },
    });

  }

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      setPermisosUsuario(model?.permisos?.map((p) => p.idPermiso))
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
        idInstitucion: model?.idInstitucion,
        permisos: model?.permisos?.map((p) => p.idPermiso),
        fechaIngreso: model?.fechaIngreso ? moment(model?.fechaIngreso) : null,
        fechaNacimiento: model?.fechaNacimiento ? moment(model?.fechaNacimiento) : null,
      });
      cargos.push(model?.cargo);
    }
  }, [form, modelLoading, model]);

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
        permisos: permisosUsuario,
        fechaIngreso: model?.fechaIngreso ? moment(model?.fechaIngreso) : null,
        fechaNacimiento: model?.fechaNacimiento ? moment(model?.fechaNacimiento) : null,
      });
    }
  }, [form, model, modelLoading, permisosUsuario])

  if (modelLoading) return <ViewLoading/>;

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >
      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        {/* Nombre | Apellido Paterno | Apellido Materno   */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item label="Número de Empleado" name="numeroEmpleado">
              <Input
                size="large"
                placeholder="Ingrese el número de empleado"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          />
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item label="Fecha de ingreso" name="fechaIngreso">
              <DatePicker
                size="large"
                autoComplete="off"
                placeholder="Seleccione una Fecha"
                format="DD/MM/YYYY"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 3}}
            lg={{span: 3}}
            xxl={{span: 3}}
          >
            <Form.Item
              label="Título"
              name="titulo"
            >
              <Input size="large" autoComplete="off"/>
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 7}}
            lg={{span: 7}}
            xxl={{span: 7}}
          >
            <Form.Item
              label="Nombre"
              name="nombre"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Input size="large" autoComplete="off"/>
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 7}}
            lg={{span: 7}}
            xxl={{span: 7}}
          >
            <Form.Item
              label="Apellido Paterno"
              name="apellidoPaterno"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Input
                size="large"
                placeholder="Ingrese una descripción"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 7}}
            lg={{span: 7}}
            xxl={{span: 7}}
          >
            <Form.Item
              label="Apellido Materno"
              name="apellidoMaterno"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Input
                size="large"
                placeholder="Ingrese una descripción"
                autoComplete="off"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item label="RFC" name="rfc" rules={reglasRfc}>
              <Input
                size="large"
                placeholder="Ingrese una descripción"
                autoComplete="off"
                minLength={10}
                maxLength={13}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item label="CURP" name="curp" rules={reglasCurp}>
              <Input
                size="large"
                placeholder="Ingrese una descripción"
                autoComplete="off"
                caracteres="[A-Z0-9]"
                minLength={18}
                maxLength={18}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item label="Fecha de Nacimiento" name="fechaNacimiento">
              <DatePicker
                size="large"
                autoComplete="off"
                placeholder="Seleccione una Fecha"
                format="DD/MM/YYYY"
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* Correo  | Teléfono  Cargo  */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Correo Electrónico"
              name="correo"
              rules={reglasCorreo}
            >
              <Input
                size="large"
                style={{width: "100%"}}
                placeholder="Introduce tu Correo electrónico"
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Contraseña"
              name="clave"
              rules={[
                {
                  required: !editing,
                  message: "Confirma tu contraseña.",
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value)
                      return Promise.resolve();

                    if (ValidarContrasena(value)) {
                      return Promise.resolve();
                    } else {
                      const mayuscula = value.match(/[A-Z]/)
                      const minuscula = value.match(/[a-z]/)
                      const digito = value.match(/[1-9]/)
                      const caracter = value.match(/(?=.*[$@$!%*?#&])/)
                      const blank = value.match(/(?=.*[$@$!%*?#&])([A-Za-z\d$@$!%*?#&]|[^ ])/)
                      const entre = value.match(/(?=.*[$@$!%*?#&])([A-Za-z\d$@$!%*?#&]|[^ ]){8,15}$/)

                      return Promise.reject(
                        new Error(
                          `La contraseña deve de contener al menos, 
                          ${entre ? "" : "Minimo 8 caracteres, Maximo 15 caracteres, "} 
                          ${mayuscula ? "" : "Al menos una letra mayúscula, "} 
                          ${minuscula ? "" : "Al menos una letra minuscula, "}
                          ${digito ? "" : "Al menos un dígito, "}
                          ${caracter ? "" : "Al menos 1 caracter especial( @$!%*?#& ), "}
                          ${blank ? "" : "No espacios en blanco, "}`
                        ));
                    }
                  },
                }),
              ]}
            >
              <Input.Password
                size="large"
                type="password"
                autocomplete="new-password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                }
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Repetir contraseña"
              name="repetirClave"
              rules={[
                {
                  required: !editing,
                  message: "Confirma tu contraseña.",
                },
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("clave") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Las contraseñas no coinciden.")
                    );
                  },
                }),
              ]}>
              <Input.Password
                size="large"
                type="password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone/> : <EyeInvisibleOutlined/>
                }
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Télefono"
              name="telefono"
              rules={reglasTelefono}
            >
              <InputNumber
                size="large"
                minLength={10}
                maxLength={10}
                style={{width: "100%"}}
                onChange={(val) => {
                  console.log(String(val).length)
                }}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item label="Celular" name="celular">
              <InputNumber
                size="large"
                minLength={10}
                maxLength={10}
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Extensión"
              name="extension">
              <InputNumber
                size="large"
                minLength={10}
                maxLength={10}
                style={{width: "100%"}}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Cargo"
              name="idCargo"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Select
                optionFilterProp="children"
                size="large"
                filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                loading={cargosLoading}
                onSearch={buscarCargos}
                placeholder="Selecciona un Cargo"
                options={cargos.map((item, index) => ({
                  value: item?.idCargo,
                  label: item?.nombre
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Departamento"
              name="idDepartamento"
            >
              <Select
                optionFilterProp="children"
                size="large"
                filterOption={(input, option) => option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                showSearch
                loading={departamentosLoading}
                onSearch={buscarDepartamento}
                placeholder="Selecciona un Departamento"
                options={departamentos.map((item, index) => ({
                  value: item?.idDepartamento,
                  label: item?.titulo
                }))}
              />
            </Form.Item>
          </Col>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 8}}
            lg={{span: 8}}
            xxl={{span: 8}}
          >
            <Form.Item
              label="Rol"
              name="rol"
              rules={[{required: true, message: "Requerido"}]}
            >
              <Select
                size={"large"}
                placeholder={"Selecciona un Rol"}
                options={roles.map(item => ({
                  key: item?.key,
                  value: item?.key,
                  label: item?.label
                }))}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={12}>
          {/** Permisos */}
          <Col span={12}>
            <Row>
              <Col span={24}>
                <Divider
                  orientation="center"
                  style={{color: "#333", fontWeight: "bold"}}
                >
                  <Title level={3}>Permisos</Title>
                </Divider>
              </Col>
              <Col span={24}>
                <Form.Item name="permisos">
                  {/* {permisoSelect} */}
                  <Checkbox.Group style={{width: "100%"}}>
                    {permisoModulo.map((modulo, _) => (
                      <React.Fragment key={`modulo ${modulo?.idPermisoModulo} `}>
                        <Divider orientation={'left'}>
                          <Checkbox
                            value={"modulo-" + modulo?.idPermisoModulo}
                            onChange={(e) => chequearModulo(e)}
                          >
                            {modulo?.nombre}
                          </Checkbox>
                        </Divider>
                        {permisos
                          .filter(
                            (permiso) =>
                              permiso.idPermisoModulo === modulo?.idPermisoModulo
                          )
                          .map((permiso, _) => (
                            <React.Fragment key={`permiso ${permiso?.idPermiso}`}>
                              <Checkbox
                                // onChange={(e) => chequearPermiso(permiso)}
                                value={permiso?.idPermiso}
                              >
                                <Popover placement="right" content={permiso?.descripcion}>
                                  {permiso?.permiso}
                                </Popover>
                              </Checkbox>
                              <br/>
                            </React.Fragment>
                          ))}
                      </React.Fragment>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {/** Áreas */}
          <Col span={12}>
            <Row gutter={10}>
              <Col span={24}>
                <Divider
                  orientation="center"
                  style={{color: "#333", fontWeight: "bold"}}
                >
                  <Title level={3}>Áreas</Title>
                </Divider>
              </Col>
              <Col span={24}>
                {model?.areas?.map(item => {
                  return <Row>
                    <Col span={4} style={{textAlign: "center"}}>
                      <CloseCircleOutlined
                        onClick={() => eliminarArea(item?.idArea, item?.nombre)}
                        style={{color: "darkRed", fontSize: "20px"}}
                      />
                    </Col>
                    <Col span={20}>
                      {item?.codigo} {item?.nombre}
                    </Col>
                  </Row>
                })}
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={24}>
                <Divider
                  orientation="center"
                  style={{color: "#333", fontWeight: "bold"}}
                >
                  <Title level={3}>Áreas Archivo</Title>
                </Divider>
              </Col>
              <Col span={24}>
                {model?.subFondoAreas?.map(item => {
                  return <Row>
                    <Col span={4} style={{textAlign: "center"}}>
                      <CloseCircleOutlined
                        onClick={() => eliminarFondoAreas(item?.idSubFondoArea, item?.nombre)}
                        style={{color: "darkRed", fontSize: "20px"}}
                      />
                    </Col>
                    <Col span={20}>
                      {item?.codigo} {item?.titulo}
                    </Col>
                  </Row>
                })}
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider
          orientation="center"
          style={{color: "#333", fontWeight: "bold"}}
        />
        <Row gutter={10}>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 5, offset: 19}}
            lg={{span: 5, offset: 19}}
            xxl={{span: 5, offset: 19}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size="large"
                htmlType="submit"
                icon={<SaveOutlined/>}
              >
                Guardar
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </DefaultLayout>
  );
};

export default UsuarioForm;
