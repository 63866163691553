import { Content } from "antd/lib/layout/layout";
import BreadCrumb from "../breadcrumb";
import { Button, Col, Row } from "antd";

const DefaultLayout = ({
  children,
  breadcrumbItems,
  buttonData,
  title,
  multipleButtonData,
  breadcrumbDisable = false,
}) => {
  const styles = {
    padding: {
      marginLeft: 15,
      marginRight: 15,
    },
    content: {
      paddingTop: 20,
      paddingBottom: 20,
      paddingLeft: 10,
      paddingRight: 10,
      minHeight: 280,
      borderRadius: 5,
    },
  };

  return (
    <div style={styles.padding}>
      {!breadcrumbDisable && <BreadCrumb breadcrumbItems={breadcrumbItems} />}
      <Row>
        <Col style={{ textAlign: "end" }}>
          {Boolean(buttonData) && (
            <Button
              onClick={buttonData.to ? buttonData.to : undefined}
              {...buttonData.props}
              icon={buttonData.icon}
            >
              {buttonData.text}
            </Button>
          )}
        </Col>
        <Col>
          {multipleButtonData ? (
            <div className="ed-lista-botones">
              {multipleButtonData.map((item, index) => (
                <Button
                  key={index}
                  tabIndex={index}
                  style={{ backgroundColor: item.color, color: item.texColor }}
                  onClick={item.to ? item.to : undefined}
                  {...item.props}
                  block
                >
                  {item.icon} {item.text}
                </Button>
              ))}
            </div>
          ) : null}
        </Col>
      </Row>
      <Content className="site-layout-background" style={styles.content}>
        {children}
      </Content>
    </div>
  );
};

export default DefaultLayout;
