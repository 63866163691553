const roles = [
  {key: 'ADMIN', label: 'ADMINISTRADOR'},
  {key: 'RGA', label: 'RESPONSABLE GENERAL DE ARCHIVO'},
  {key: 'RAC', label: 'RESPONSABLE DE ARCHIVO DE CONCENTRACION'},
  {key: 'RAT', label: 'RESPONSABLE DE ARCHIVO DE TRÁMITE'},
  {key: 'DIPUTADO', label: 'DIPUTADO'},
  {key: 'USUARIO', label: 'USUARIO'},
  {key: 'EMPLEADO', label: 'EMPLEADO'},
]

export {roles};