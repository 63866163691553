import React, {useEffect, useState} from 'react'

import {
  Col,
  Row,
  Button,
  Form,
  Input,
  message,
  Select,
  Modal, Switch, Upload, Avatar
} from 'antd';

import {
  ArrowLeftOutlined,
  BankOutlined,
  SaveOutlined,
  FileAddOutlined, UserOutlined
} from '@ant-design/icons';

import {useHistory} from 'react-router-dom';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import {useQuery, useModel, useApp, useAuth} from "../../../hooks";
import {ViewLoading} from '../../../components';
import StatusResponse from '../../../services/statusResponse';
import {FaParking} from "react-icons/fa";

const PartidoPoliticoDetalle = () => {

  const titulo = 'Partido Politico';
  const url = '/gestion-general/partidos-politicos';
  const [form] = Form.useForm();
  const history = useHistory();
  const {token} = useApp();
  const {user} = useAuth();
  const {REACT_APP_API_URL: baseUrl} = process.env;

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const [loading, setLoading] = useState(false)
  const [estatus, setEstatus] = useState(false)
  const [archivoCargando, setArchivoCargando] = useState(false);
  const [listaArchivos, setListaArchivos] = useState([]);


  const {
    model,
    modelLoading,
  } = useModel({
    name: 'partido',
    id: id,
  });

  console.log(model);

  const breadcrumbItems = [
    {
      name: "Partidos Politicos",
      to: url,
      icon: <FaParking/>,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ? url + '/editar?id=' + id
        : url + '/nuevo',
      icon: <FileAddOutlined/>,
    },
  ];

  const onChangeImagen = ({fileList: newFileList}) => {
    let _archivo = newFileList[0]?.originFileObj;
    const isImage = _archivo.type.includes('image/');

    if (!isImage) {
      message.error(`${_archivo.name} no es un archivo de Imagen`);
      return;
    }

    subirImagenPerfil(newFileList);

    setListaArchivos(newFileList);
  };

  const subirImagenPerfil = async (file, idPartido) => {
    try {
      setArchivoCargando(true);

      let _archivo = file[0]?.originFileObj;

      if (!_archivo) {
        message.info({
          content: 'Debes de seleccionarun archivo',
          style: {marginTop: '20vh'},
        });
        return
      }

      const form = new FormData();
      form.append('archivo', _archivo);
      form.append('idPartido', model?.idPartido);

      const response = await fetch(baseUrl + '/v1/partido/subir-imagen', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      });

      console.log(response)

    } catch (error) {
      console.log('error al cargar archivo: ', error);
    } finally {
      setArchivoCargando(false);
      window.location.reload(false);
    }
  };

  const buttonData = {
    text: "Volver",
    to: () => history.push(url),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const onFinish = async (values) => {
    let body = {...values}
    if (editing) {
      body.idPartido = id;
    }

    body.estatus = (estatus === true ? 1 : 0);
    try {
      setLoading(true)
      const res = await StatusResponse.post('partido/guardar', body);
      if (res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if (res?.status === 400 && res?.errores !== null) {
          const arrayError = Object.values(res?.errores);
          console.log(arrayError);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>{arrayError.map((m, i) =>
                <span key={(i + 1)}> -{m} <br/>
              </span>)
              }</div>
            )
          });
          //cuando el dato ya existe no se puede guardar en BD
        } else if (res?.status === 400 && res?.errores === null) {
          message.error({
            content: res?.mensaje,
            style: {marginTop: '20vh'},
          });
          //todo salió bien
        } else if (res?.status === 200) {
          message.success({
            content: res?.mensaje,
            style: {marginTop: '20vh'}
          });
          history.push(url);
        }
      }
    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
        idArea: model?.idArea,
      })
      setEstatus(model.estatus === 1 ? true : false)
    }
    return () => mounted = false;
  }, [form, modelLoading, model])

  if (modelLoading) return (<ViewLoading/>);

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >

      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <Row gutter={{xs: 10, sm: 10, md: 10, lg: 10}}>
          <Col
            className="gutter-row"
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 24, offset: 0}}
            lg={{span: 24, offset: 0}}
            xxl={{span: 24, offset: 0}}
          >
            <Upload
              //action={baseUrl + "/reportes/upload"}
              beforeUpload={() => false}
              multiple={true}
              listType="picture-card"
              fileList={listaArchivos}
              onChange={onChangeImagen}
              style={{width:'100%', height:'100%'}}
            >
              {listaArchivos.length < 1 && model?.logo ? <Avatar shape="square" size={100} icon={<UserOutlined/>} src={model?.logo} style={{width:"200px"}}/> : '+ Subir foto' }
            </Upload>
            <label>Partido Político</label>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Abreviatura"
              name="abreviatura"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Nombre"
              name="nombre"
            >
              <Input
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Dirección"
              name="direccion"
            >
              <Input
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 12, offset: 0}}
            sm={{span: 12, offset: 0}}
            md={{span: 12, offset: 0}}
            lg={{span: 12, offset: 0}}
            xxl={{span: 12, offset: 0}}
          >
            <Form.Item
              label="Orden"
              name="orden"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={{xs: 10, sm: 10, md: 10, lg: 10}}>
          <Col
            xs={{span: 24, offset: 0}}
            sm={{span: 24, offset: 0}}
            md={{span: 5, offset: 19}}
            lg={{span: 5, offset: 19}}
            xxl={{span: 5, offset: 19}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined/>}
              >Guardar</Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </DefaultLayout>
  )
}

export default PartidoPoliticoDetalle

//Blvd Luis Donaldo Colosio y Kennedy #4 Colonia Casa Blanca, Hermosillo 83100. Conmutador: (662)108-03-00