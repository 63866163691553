import React, { useState, useMemo, useCallback } from "react";
import { Table, Modal } from 'antd';
import {
  EditOutlined, 
  PlusOutlined,
  DeleteOutlined, 
  PartitionOutlined, 
  CloseCircleOutlined,
} from "@ant-design/icons";
import {useHistory} from 'react-router-dom';
import { useModels } from '../../../hooks';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import StatusResponse from '../../../services/statusResponse';
import { SimpleTableLayout } from '../../../components/layouts';

const Areas = () => {

  const titulo = "Áreas";
  const url = '/gestion-general/areas'
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [ limite, setLimite ] = useState(10);

  const defaultText = (prop, item) => prop ? <a onClick={()=>history.push(`${url}/editar?id=${item?.idArea}`)}>
    {prop}</a> : <span style={{color: '#c7c3c3'}}>---</span>;
  
  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'area',
    expand: 'fondo, adscripcion',
    ordenar: 'idArea-asc',
    limite: limite
  });
  
  const breadcrumbItems = [
    {
      name: "Áreas",
      to: '/gestion-general/areas/',
      icon: <PartitionOutlined />,
    },
  ];

  const buttonData = {
    text: "Agregar",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined />,
    props: { disabled: false, type: 'primary', }
  };
  
  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item)=>(
        <ActionsButton
          key={item.idArea}
          text={"Acción"}
          options={[
            {
              name:'Editar',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idArea}`)
            },
            {
              name:'Diagrama',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/diagrama?id=${item.idArea}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () => modalEliminar(item),
              styleProps: { color: '#ff4d4f' }
            }
          ]}
        />
      )
    },
    {
      title: '#',
      dataIndex: 'idArea',
      key: 'idArea',
      render: defaultText,
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
      render: defaultText,
    },
    {
      title: 'Fondo',
      dataIndex: 'idFondo',
      key: 'idFondo',
      render:(_, item) => (item?.fondo?.titulo),
    },
    {
      title: 'Adscripción',
      dataIndex: 'idAdscripcion',
      key: 'idAdscripcion',
      render:(_, item) => (item?.adscripcion?.nombre),
    },
  ];

  const onSearch = async (search) => {
    setSearching(true);
    let params = {};
    if (search.length > 0) params = { ...params, buscar: search};
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
    if(!modelsLoading) setSearching(false);
  };
  
  const onSetPageCallback = useCallback(
    async ( page, size ) => {
      setLimite(size);
      await refreshModels ( true, {
        ordenar: 'idArea-desc',
        pagina: page,
        limite: size,
      })
    }, [ refreshModels ]
  );
  
  //configuracion de la paginación
  const configPagination = useMemo( ()=> {
    if (modelsPage){
      let size = limite;
      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: ( _, newSize)  => ( size = newSize ),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} áreas.`,
        locale: { items_per_page: '/ página' },
        pageSizeOptions: [10, 20, 30].filter( val => val <= modelsPage.total ),
        showSizeChanger: true
      }
    }return null;
  }, [ onSetPageCallback, limite, modelsPage ]);

  // modal para eliminar
  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar el área "${valor?.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("area/eliminar",{ idArea: valor.idArea});
        return new Promise((resolve, reject)=>{
          try {
            if (valor.idArea > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'idArea-asc',
                  pagina: 1,
                  limite: limite
                });
                resolve();
              } else if ( res?.status === 400 ) {
                Modal.error({
                  title: "Error: no se logro eliminar área",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      searchLoading={searching}
      onSearchClicked={onSearch}
      emptyText='Aún no hay áreas registrados.'
      children={
        <>
          <Table
            dataSource={models}
            rowKey="idArea"
            loading={modelsLoading}
            pagination={configPagination}
            columns={columns}
            size='small'
            scroll={{ x: 1000 }}
          />
        </>
      }
    />
  );
}

export default Areas;