import React, { useEffect, useMemo, useState } from "react";
import { DefaultLayout } from "../../components/layouts";
import { Avatar, Col, Row, Select, Typography } from "antd";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import HighchartsExporting from "highcharts/modules/exporting";
import { useAuth, useModels } from "../../hooks";
import { Anios } from "../../utilities";
import { emptyRequest } from "../../constants/requests";
import { NumericFormat } from "react-number-format";

const generateDefaultChartOptions = (
  chartType = "pie",
  options = {},
  callback
) => ({
  chart: {
    type: chartType,
    inverted: options.inverted || false,
    options3d: {
      enabled: chartType === "pie",
      alpha: 45,
      beta: 0,
    },
    height: options.chartHeight || null,
  },
  colors: options?.colores || [
    "#2f7ed8",
    "#0d233a",
    "#8bbc21",
    "#910000",
    "#1aadce",
    "#492970",
    "#f28f43",
    "#77a1e5",
    "#c42525",
    "#a6c96a",
  ],
  credits: {
    enabled: false,
  },
  title: {
    text: options?.titulo || "",
  },
  plotOptions: {
    [chartType]: {
      innerSize: 100,
      depth: 45,
      events: {
        click: typeof callback === "function" ? callback : () => { },
      },
    },
  },
  yAxis: {
    title: {
      text: 'Monto'
    }
  },
  xAxis: {
    categories: [
      'Enero',
      'Febrero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ]
  },
  series: [
    {
      name: options?.nombre || "NOMBRE DE LA COLECCION DE DATOS",
      data: options?.datos || [],
    },
  ],
  subtitle: {
    text: options?.subtitulo || "SUBTITULO POR DEFAULT",
  },
  ...options?.options,
});

const Graficas = () => {
  const { user } = useAuth();

  const [request, setRequest] = useState(emptyRequest);
  const [anoSeleccionado, setAnoSeleccionado] = useState('');

  const requestExtraParams = useMemo(
    () => ({
      anio: anoSeleccionado
    }), [anoSeleccionado]);
  const requestParams = useMemo(
    () => ({
      name: "fondo-legislativo",
      ordenar: "creado-desc",
      extraParams: requestExtraParams
    }), [requestExtraParams]);

  const [models] = useModels(request);

  const asistenciasSolicitadas = models.reduce((cantidad, asistencia) => {
    if (asistencia["tipo"] === "FGL" && asistencia["estatus"] === "NUEVO") {
      cantidad++;
    }
    return cantidad;
  }, 0);

  const asistenciasAprobadas = models.reduce((cantidad, asistencia) => {
    if (asistencia["tipo"] === "FGL" && asistencia["estatus"] === "APROBADO") {
      cantidad++;
    }
    return cantidad;
  }, 0);

  const asistenciasRechazadas = models.reduce((cantidad, asistencia) => {
    if (asistencia["tipo"] === "FGL" && asistencia["estatus"] === "RECHAZADO") {
      cantidad++;
    }
    return cantidad;
  }, 0);

  const repSolicitadas = models.reduce((cantidad, rep) => {
    if (rep["tipo"] === "51" && rep["estatus"] === "NUEVO") {
      cantidad++;
    }
    return cantidad;
  }, 0);

  const repAprobadas = models.reduce((cantidad, rep) => {
    if (rep["tipo"] === "51" && rep["estatus"] === "APROBADO") {
      cantidad++;
    }
    return cantidad;
  }, 0);

  const repRechazadas = models.reduce((cantidad, rep) => {
    if (rep["tipo"] === "51" && rep["estatus"] === "RECHAZADO") {
      cantidad++;
    }
    return cantidad;
  }, 0);

  const chartAsistLeg = generateDefaultChartOptions("pie", {
    colores: ["#838383", "#006843", "#f00821"],
    titulo: "Asistencia Legislativa y atención Ciudadana",
    nombre: "Solicitudes",
    subtitulo: " ",
    datos: [
      {
        name: "Solicitadas",
        y: asistenciasSolicitadas,
      },
      {
        name: "Aprobadas",
        y: asistenciasAprobadas,
      },
      {
        name: "Rechazadas",
        y: asistenciasRechazadas,
      },
    ],
  });

  const chartRepCiud = generateDefaultChartOptions("pie", {
    colores: ["#838383", "#006843", "#f00821"],
    titulo: "Representación Legislativa",
    nombre: "Solicitudes",
    subtitulo: " ",
    datos: [
      {
        name: "Solicitadas",
        y: repSolicitadas,
      },
      {
        name: "Aprobadas",
        y: repAprobadas,
      },
      {
        name: "Rechazadas",
        y: repRechazadas,
      },
    ],
  });

  const montosALC = useMemo(() => {
    let obj = {
      enero: 0,
      febrero: 0,
      marzo: 0,
      abril: 0,
      mayo: 0,
      junio: 0,
      julio: 0,
      agosto: 0,
      septiembre: 0,
      octubre: 0,
      noviembre: 0,
      diciembre: 0,
      solicitado: 0,
      aprobado: 0,
    }
    if (models) {
      for (let i = 0, l = models?.length; i < l; i++) {
        if (models[i]?.tipo !== '51') {
          let monto = 0;
          if (models[i]?.monto) {
            monto = parseFloat(models[i]?.monto);
          }

          obj.solicitado += monto;
          if (models[i]?.estatus === 'APROBADO') {
            obj.aprobado += monto;
          }

          if (models[i]?.mes === 1) {
            obj.enero += monto;
          } else if (models[i]?.mes === 2) {
            obj.febrero += monto;
          } else if (models[i]?.mes === 3) {
            obj.marzo += monto;
          } else if (models[i]?.mes === 4) {
            obj.abril += monto;
          } else if (models[i]?.mes === 5) {
            obj.mayo += monto;
          } else if (models[i]?.mes === 6) {
            obj.junio += monto;
          } else if (models[i]?.mes === 7) {
            obj.julio += monto;
          } else if (models[i]?.mes === 8) {
            obj.agosto += monto;
          } else if (models[i]?.mes === 9) {
            obj.septiembre += monto;
          } else if (models[i]?.mes === 10) {
            obj.octubre += monto;
          } else if (models[i]?.mes === 11) {
            obj.noviembre += monto;
          } else {
            obj.diciembre += monto;
          }
        }
      }
    }

    return obj;
  }, [models])

  const montosRL = useMemo(() => {
    let obj = {
      enero: 0,
      febrero: 0,
      marzo: 0,
      abril: 0,
      mayo: 0,
      junio: 0,
      julio: 0,
      agosto: 0,
      septiembre: 0,
      octubre: 0,
      noviembre: 0,
      diciembre: 0,
      solicitado: 0,
      aprobado: 0,
    }
    if (models) {
      for (let i = 0, l = models?.length; i < l; i++) {
        if (models[i]?.tipo === '51') {
          let monto = 0;
          if (models[i]?.monto) {
            monto = parseFloat(models[i]?.monto);
          }

          obj.solicitado += monto;
          if (models[i]?.estatus === 'APROBADO') {
            obj.aprobado += monto;
          }

          if (models[i]?.mes === 1) {
            obj.enero += monto;
          } else if (models[i]?.mes === 2) {
            obj.febrero += monto;
          } else if (models[i]?.mes === 3) {
            obj.marzo += monto;
          } else if (models[i]?.mes === 4) {
            obj.abril += monto;
          } else if (models[i]?.mes === 5) {
            obj.mayo += monto;
          } else if (models[i]?.mes === 6) {
            obj.junio += monto;
          } else if (models[i]?.mes === 7) {
            obj.julio += monto;
          } else if (models[i]?.mes === 8) {
            obj.agosto += monto;
          } else if (models[i]?.mes === 9) {
            obj.septiembre += monto;
          } else if (models[i]?.mes === 10) {
            obj.octubre += monto;
          } else if (models[i]?.mes === 11) {
            obj.noviembre += monto;
          } else {
            obj.diciembre += monto;
          }
        }
      }
    }

    return obj;
  }, [models])

  const chartBarrasALC = generateDefaultChartOptions("column", {
    titulo: 'Asistencia Legislatva y atención Ciudadana',
    subtitulo: 'Meses',
    nombre: 'Mes',
    datos: [
      {
        name: 'Enero',
        y: montosALC?.enero
      },
      {
        name: 'Febrero',
        y: montosALC?.febrero
      },
      {
        name: 'Marzo',
        y: montosALC?.marzo
      },
      {
        name: 'Abril',
        y: montosALC?.abril
      },
      {
        name: 'Mayo',
        y: montosALC?.mayo
      },
      {
        name: 'Junio',
        y: montosALC?.junio
      },
      {
        name: 'Julio',
        y: montosALC?.julio
      },
      {
        name: 'Agosto',
        y: montosALC?.agosto
      },
      {
        name: 'Septiembre',
        y: montosALC?.septiembre
      },
      {
        name: 'Octubre',
        y: montosALC?.octubre
      },
      {
        name: 'Noviembre',
        y: montosALC?.noviembre
      },
      {
        name: 'Diciembre',
        y: montosALC?.diciembre
      },
    ]
  })

  const chartBarrasRL = generateDefaultChartOptions("column", {
    titulo: 'Representación Legislativa',
    subtitulo: 'Meses',
    nombre: 'Mes',
    datos: [
      {
        name: 'Enero',
        y: montosRL?.enero,
      },
      {
        name: 'Febrero',
        y: montosRL?.febrero
      },
      {
        name: 'Marzo',
        y: montosRL?.marzo
      },
      {
        name: 'Abril',
        y: montosRL?.abril
      },
      {
        name: 'Mayo',
        y: montosRL?.mayo
      },
      {
        name: 'Junio',
        y: montosRL?.junio
      },
      {
        name: 'Julio',
        y: montosRL?.julio
      },
      {
        name: 'Agosto',
        y: montosRL?.agosto
      },
      {
        name: 'Septiembre',
        y: montosRL?.septiembre
      },
      {
        name: 'Octubre',
        y: montosRL?.octubre
      },
      {
        name: 'Noviembre',
        y: montosRL?.noviembre
      },
      {
        name: 'Diciembre',
        y: montosRL?.diciembre
      },
    ],
  })

  HighchartsExporting(Highcharts);

  useEffect(() => {
    setRequest(requestParams);
    return () => {
      setRequest({});
    };
  }, [requestParams]);

  // console.log(anoSeleccionado)

  return (
    <DefaultLayout title="Documentación">
      <Row>
        <Col span={6}>
          <Select
            autoComplete="off"
            size="large"
            style={{ width: "100%" }}
            placeholder="Seleccione un Año"
            options={Anios().map((item) => ({
              key: item.value,
              value: item.value,
              label: item.label,
            }))}
            onChange={(v) => setAnoSeleccionado(v)}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24} md={12}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartAsistLeg}
                constructorType={"chart"}
              />
            </Col>
            <Col span={24} md={12}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartRepCiud}
                constructorType={"chart"}
              />
            </Col>
          </Row>
          <Row justify={'space-between'}>
            <Col>
              <Row>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartBarrasALC}
                  constructorType={"chart"}
                />
              </Row>
              <Row>
                <Col span={24} md={12}>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <Avatar style={{ backgroundColor: 'blue' }} />
                    </Col>
                    <Col>
                      <Typography.Title level={4}>
                        Solicitado:
                        <NumericFormat
                          prefix="$"
                          decimalSeparator="."
                          thousandSeparator=','
                          displayType="text"
                          value={montosALC?.solicitado}
                        />
                      </Typography.Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} md={12}>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <Avatar style={{ backgroundColor: 'blue' }} />
                    </Col>
                    <Col>
                      <Typography.Title level={4}>
                        Aprobado:
                        <NumericFormat
                          prefix="$"
                          decimalSeparator="."
                          thousandSeparator=','
                          displayType="text"
                          value={montosALC?.aprobado}
                        />
                      </Typography.Title>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={chartBarrasRL}
                  constructorType={"chart"}
                />
              </Row>
              <Row>
                <Col span={24} md={12}>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <Avatar style={{ backgroundColor: 'blue' }} />
                    </Col>
                    <Col>
                      <Typography.Title level={4}>
                        Solicitado:
                        <NumericFormat
                          prefix="$"
                          decimalSeparator="."
                          thousandSeparator=','
                          displayType="text"
                          value={montosRL?.solicitado}
                        />
                      </Typography.Title>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} md={12}>
                  <Row gutter={[10, 10]}>
                    <Col>
                      <Avatar style={{ backgroundColor: 'blue' }} />
                    </Col>
                    <Col>
                      <Typography.Title level={4}>
                        Aprobado:
                        <NumericFormat
                          prefix="$"
                          decimalSeparator="."
                          thousandSeparator=','
                          displayType="text"
                          value={montosRL?.aprobado}
                        />
                      </Typography.Title>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </DefaultLayout>
  );
};

export default Graficas;
