import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';
import { Modal, Table } from 'antd';
import { useHistory } from 'react-router-dom';
import {
  UserOutlined,
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import { useModels } from '../../../hooks';
import StatusResponse from '../../../services/statusResponse';
import { Link } from 'react-router-dom';

const Configuracion = () => {
  const titulo = 'Configuracion';
  const url = '/gestion-general/configuracion';
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [limite, setLimite] = useState(10);
  const [pagina, setPagina] = useState(0);
  const [request, setRequest] = useState({});

  const requestMemoParams = useMemo(
    () => ({
      name: 'configuracion',
      ordenar: 'idConfiguracion-desc',
      limite: limite,
      pagina: pagina,
    }),
    [limite, pagina]
  );

  const [
    models,
    modelsLoading,
    modelsError,
    modelsPage,
    refreshModels,
  ] = useModels(request);

  const breadcrumbItems = [
    {
      name: 'Configuracion',
      icon: <UserOutlined />,
      to: '/gestion-general/configuracion',
    },
  ];

  const buttonData = {
    text: 'Agregar nuevo',
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined />,
    props: { disabled: false, type: 'primary' },
  };

  const defaultText = (prop, item) => prop ? <a href={`${url}/editar?id=${item?.id}`} onClick={() => history.push(`${url}/editar?id=${item?.idConfiguracion}`)}>---</a> : <span style={{ color: '#c7c3c3' }}>---</span>;


  const onSearch = async (search) => {
    setSearching(true);
    let params = {};
    if (search.length > 0) params = { ...params, buscar: search };
    params = { ...params, pagina: 0 };
    await refreshModels(true, params);
    if (!modelsLoading) setSearching(false);
  };

  const onSetPageCallback = useCallback(
    async (page, size) => {
      setPagina(page);
      setLimite(size);
      await refreshModels(true, {
        ordenar: 'id-desc',
        pagina: page,
        limite: size,
      });
    },
    [refreshModels]
  );

  const configPagination = useMemo(() => {
    if (modelsPage) {
      let size = limite;

      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: (_, newSize) => (size = newSize),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) =>
          `${range[0]}-${range[1]} de ${total} distritos.`,
        locale: { items_per_page: '/ página' },
        pageSizeOptions: [10, 20, 30].filter((val) => val <= modelsPage.total),
        showSizeChanger: true,
      };
    }
    return null;
  }, [onSetPageCallback, limite, modelsPage]);

  // modal para eliminar
  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar configuración "${valor?.parametro}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("/v1/configuracion/eliminar", { idConfiguracion: valor.idConfiguracion });
        return new Promise((resolve, reject) => {
          try {
            if (valor.idConfiguracion > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'idConfiguracion-asc',
                  pagina: 1,
                  limite: limite
                });
                resolve();
              } else if (res?.status === 400) {
                Modal.error({
                  title: "Error: no se logro eliminar configuracion",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };

  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item) => (
        <ActionsButton
          key={item.idConfiguracion}
          text={'Acción'}
          options={[
            {
              name: 'Editar',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item?.idConfiguracion}`),
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () => modalEliminar(item),
              styleProps: { color: '#ff4d4f' }
            }
          ]}
        />
      ),
    },
    {
      title: 'Parametro',
      key: 'parametro',
      dataIndex: 'parametro',
      ellipsis: true,
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idConfiguracion}`}>{`${item?.parametro}` || ''}</Link>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'valor',
      key: 'valor',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idConfiguracion}`}>{`${item?.valor}` || ''}</Link>
      ),
    },
    {
      title: 'Comentario',
      dataIndex: 'comentario',
      key: 'comentario',
      render: (_, item) => (
        <Link to={`${url}/editar?id=${item?.idConfiguracion}`}>{`${item?.comentario}` || ''}</Link>
      ),
    },
  ];

  useEffect(() => {
    setRequest(requestMemoParams);
  }, [requestMemoParams]);

  return (
    <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder="Buscar..."
      searchLoading={searching}
      onSearchClicked={onSearch}
      emptyText="Aún no hay diputados registrados."
      children={
        <>
          <Table
            dataSource={models}
            rowKey="idConfiguracion"
            columns={columns}
            loading={modelsLoading}
            pagination={configPagination}
            size="small"
            scroll={{ x: 1000 }}
          />
        </>
      }
    />
  );
};

export default Configuracion;
