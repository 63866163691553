import React, { useEffect, useState } from 'react';
import { 
  Col, 
  Row, 
  Button, 
  Form, 
  Input,
  message,
  Select,
  Modal
} from 'antd';
import { 
  ArrowLeftOutlined, 
  ContactsOutlined,
  SaveOutlined,
  FileAddOutlined
} from '@ant-design/icons';
import{ useHistory } from 'react-router-dom';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import { useQuery, useModels, useModel} from "../../../hooks";
import { ViewLoading } from '../../../components';
import StatusResponse from '../../../services/statusResponse';


const CargoForm = () => {

  const titulo = 'Cargos';
  const [form] = Form.useForm();
  const history = useHistory();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;
  const [loading, setLoading] = useState(false)

  const { Option } = Select;

  const {
    model,
    modelLoading,
  } = useModel({
    name: 'cargo',
    id: id,
    expand: 'area'
  });

  const [ 
    areas,
    areasLoading,
  ] = useModels({
    name: 'area',
    ordenar: 'idArea-desc',
    limit: 10,
  });

  const breadcrumbItems = [
    {
      name: "Cargos",
      to: '/gestion-general/cargos/',
      icon: <ContactsOutlined />,
    },
    {
      name: editing ? "Editar" : "Nuevo",
      to: editing ? '/gestion-general/cargos/editar?id='+id :
        '/gestion-general/cargos/nuevo',
      icon: <FileAddOutlined />,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/cargos/'),
    icon: <ArrowLeftOutlined />,
    props: { disabled: false, type: 'primary', }
  };

  const onFinish = async (values) => {
    const {
      nombre,
      idArea,
    } = values;
    let body = {
      nombre,
      idArea
    }
    if(editing) {
      body.idCargo = id;
    }
    try {
      setLoading(true)
      const res = await StatusResponse.post('cargo/guardar', body);
      if(res) {
        //Si estatus 400 y "errores" es diferente a nulo
        if( res?.status === 400 && res?.errores !== null ) {
          const arrayError = Object.values(res?.errores);
          Modal.error({
            title: res?.mensaje,
            content: (
              <div>{ arrayError.map((m,i) =>
              <span key={(i+1)}> -{m} <br />
              </span> )
              }</div>
            )
          });
          console.log(arrayError)
          //cuando el dato ya existe no se puede guardar en BD
        } else if ( res?.status === 400 && res?.errores === null ) {
          message.error({
            content: res?.mensaje,
            style: { marginTop: '20vh' },
          });
          //todo salió bien
        } else if ( res?.status === 200 ) {
          message.success({
            content: res?.mensaje,
            style: { marginTop: '20vh' }
          });
          history.push('/gestion-general/cargos');
        }
      }
    } catch (e) {
      console.log('Error al guardar: ',e);
    } finally {
      setLoading(false)
    }
  }

  const onFinishFailed = ({ values, errorFields, outOfDate  }) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log( values, errorFields, outOfDate );
  };

  useEffect(()=>{
    let mounted = true;
    if (mounted && model && !modelLoading) {
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
        idArea: model?.idArea,
      })
      console.log(model?.nombre)
    };
    return () => mounted = false;
  }, [form, modelLoading, model])

  if( modelLoading ) return (<ViewLoading /> );

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >

      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <Row gutter={{ xs: 10, sm: 10, md: 10, lg: 10 }}>   
          <Col
            className="gutter-row"
            xs={{ span: 12, offset: 0 }}
            sm={{ span: 12, offset: 0 }}
            md={{ span: 12, offset: 0 }}
            lg={{ span: 12, offset: 0 }}
            xxl={{ span: 12, offset: 0 }}
          >
            <Form.Item
              label="Nombre del Cargo"
              name="nombre"
              rules={[{ required: true, message: 'Requerido' }]}
            >
              <Input
                placeholder="Nombre del Cargo"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col 
            className='gutter-row'
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span:  8, offset: 0 }}
            lg={{ span:  8, offset: 0 }}
          >
            <Form.Item
              label="Área"
              name="idArea"
              rules={[{ required: true, message: 'Requerido' }]}
              hasFeedback 
            >
              <Select
                loading={areasLoading}
                disabled={areasLoading}
                placeholder="Seleccionar Área"
                
              >
                { areas.map((item, index) => (
                  <Option key={index} value={item.idArea}>{ item.nombre }</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={{xs:10,sm:10,md:10,lg:10}}>
          <Col
            xs={{span:24,offset:0}}
            sm={{span:24,offset:0}}
            md={{span:5,offset:19}}
            lg={{span:5,offset:19}}
            xxl={{span:5,offset:19}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined />}
              >Guardar</Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>

    </DefaultLayout>
  );
};

export default CargoForm;
