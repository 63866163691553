import React from "react";

import { emptyRequest, getRequest } from "../constants/requests";
import { useHttp } from "./useHttp";
import { useApp } from "./useApp";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import StatusResponse from "../services/statusResponse";
import { Respuestas } from "../utilities";

const AuthContext = React.createContext();

const empty = emptyRequest();

export function AuthProvider(props) {
  const {token, setToken} = useApp();
  const [sessionRequest, setSessionRequest] = React.useState(empty);
  const [session, sessionLoading] = useHttp(sessionRequest);
  const [agendaRequest, setAgendaRequest] = React.useState(empty);
  const [agendaResponse, agendaResponseLoading] = useHttp(agendaRequest);

  React.useEffect(() => {
    if (session && !sessionLoading) {
      if (session?.resultado?.[0]) {
        const {token} = session.resultado[0];
        setToken(token);
      }
    }
  }, [session, sessionLoading, setToken]);

  React.useEffect(() => {
    if (token) {
      const agendaReq = getRequest("usuario/perfil", {
        expand: "",
      });
      setAgendaRequest(() => agendaReq);
    } else {
      setAgendaRequest(() => empty);
    }
  }, [token]);

  const signIn = React.useCallback(async (email, password) => {
    try {
      if (email !== "" && password !== "") {
        const res = await StatusResponse.post("login", {correo: email, clave: password}, false)
        if (Respuestas(res))
          setToken(res.response.resultado[0]?.token);
      }
    } catch (e) {
      console.log(e);
    }
  }, [setToken]);

  const signOut = React.useCallback(async () => {
    try {
      Modal.confirm({
        title: 'Atención',
        icon: <ExclamationCircleOutlined/>,
        content: '¿Estás seguro de que deseas cerrar tu sesión?',
        okText: 'Cerrar sesión',
        cancelText: 'Cancelar',
        onOk: async () => {
          setToken(null);
          setSessionRequest(empty);
          localStorage.clear();
        },
      });
    } catch (e) {
      console.log(e);
    }
  }, [setToken]);

  const memData = React.useMemo(() => {
    return {
      session,
      sessionLoading,
      user:
        agendaResponse &&
        agendaResponse.resultado &&
        agendaResponse.resultado.length > 0
          ? {
            ...agendaResponse.resultado[0],
            permisoExtra: agendaResponse.detalle,
          }
          : null,
      userLoading: agendaResponseLoading,
      signIn,
      signOut,
    };
  }, [
    session,
    sessionLoading,
    agendaResponse,
    agendaResponseLoading,
    signIn,
    signOut,
  ]);

  return <AuthContext.Provider value={memData} {...props} />;
}

export function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    // eslint-disable-next-line no-throw-literal
    throw "error: auth context not defined.";
  }
  return context;
}
