import React, {useEffect, useMemo, useState} from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Modal,
  Table,
  Button,
  Select,
  Divider,
  message,
  InputNumber, Grid,
} from 'antd';
import {
  SaveOutlined,
  DeleteOutlined,
  FileAddOutlined,
  UserAddOutlined,
  ArrowLeftOutlined,
  PartitionOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import ActionsButton from "../../../components/actionsbutton";
import {useHistory} from 'react-router-dom';
import StatusResponse from '../../../services/statusResponse';
import {ViewLoading} from '../../../components';
import {useQuery, useModels, useModel} from "../../../hooks";
import TextArea from "antd/es/input/TextArea";
import {log} from "@craco/craco/lib/logger";


const AreaGantt = () => {

  const {Option} = Select;
  const titulo = 'Áreas';
  const [form] = Form.useForm();
  const history = useHistory();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;

  const [loading, setLoading] = useState(false);
  const [idsUsuariosArea, setIdsUsuariosArea] = useState([]);
  const [usuariosTabla, setUsuariosTabla] = useState([]);
  const [indicadores, setIndicadores] = useState([])
  const [conceptos, setConceptos] = useState([])
  const [valores, setValores] = useState([])

  const idAreaUsuariosMemo = useMemo(() => ({idArea: id}), [id]);

  // Adscripcion
  const {
    model, modelLoading,
  } = useModel({
    name: 'area',
    id: id,
    expand: 'fondo, gantt'
  });

  const breadcrumbItems = [
    {
      name: "Áreas",
      to: '/gestion-general/areas/',
      icon: <PartitionOutlined/>,
    },
    {
      name: editing ? "Área" : "Nuevo",
      to: editing ? '/gestion-general/areas/editar?id=' + id : '/gestion-general/areas/nuevo',
      icon: <FileAddOutlined/>,
    },
  ];

  const modalEliminar = (usuario) => {

    Modal.confirm({
      title: "Remover",
      icon: <CloseCircleOutlined style={{color: "#ff5722"}}/>,
      content: `¿Desea remover del área el usuario '${usuario?.nombre} ${usuario?.apellidoPaterno} ${usuario?.apellidoMaterno}' ?`,
      okText: "Sí",
      cancelText: "Cancelar",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {

        let res = null
        if (editing) {
          res = await StatusResponse.delete('/v1/area-usuario', {idArea: id, idUsuario: usuario?.idUsuario});
        }

        let _usuariosTabla = [...usuariosTabla];
        const index = _usuariosTabla.findIndex(u => u.idUsuario === usuario.idUsuario)
        if (index > -1) {
          _usuariosTabla.splice(index, 1)
        }
        setUsuariosTabla(_usuariosTabla)
        try {
          if (usuario?.idUsuario > 0) {
            if (res && res?.status === 200) {
              Modal.success({
                title: 'Eliminado',
                content: res?.mensaje,
              });
            }
          } else if (res?.status === 400) {
            Modal.error({
              title: 'Error: no se logro eliminar el usuario',
              content: res?.mensaje,
            });
          }
        } catch (error) {
          console.log('error al eliminar usuario: ', error)
        }

      },
    });
  };

  const onFinish = async (values) => {

    let body = {...values}
    if (editing) {
      body.idPadre = id;
      body.tipo = 2
    }

    console.log(body);
    try {
      const res = await StatusResponse.post('area/guardar-gantt', body);

      if (res && res?.status === 200) {
        Modal.success({
          title: 'Guardado',
          content: res?.mensaje,
        });
      }


    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      setUsuariosTabla(model?.usuarios)
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
        usuarios: []
      })
    }

    console.log(model)

    let _ganttValores = model?.gantt;
    let _conceptos = [];
    let _indicadores = [];
    let _valores = [];

    _ganttValores?.forEach((item, index) => {
      if (!_conceptos.includes(item?.nombre)) {
        _conceptos.push(item?.nombre);
      }
      if (!_indicadores.includes(item?.valor)) {
        _indicadores.push(item?.valor);
      }
      _valores[item.valor + "/" + item.nombre] =  item.valorTipo;
    })

    form.setFieldsValue({
      ..._valores
    });

    setConceptos(_conceptos);
    setIndicadores(_indicadores);

    return () => mounted = false;
  }, [form, modelLoading, model]);

  const crearDiagrama = async () => {

    let body = {}
    try {
      setLoading(true)

      body.idPadre = id
      body.tipo = 2

      const res = await StatusResponse.post('area/diagrama-crear', body);

      if (res && res?.status === 200) {
        Modal.success({
          title: 'Guardado',
          content: res?.mensaje,
        });
      }

    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  }

  if (modelLoading) return <ViewLoading/>;

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
    >
      <Row>
        <Col span={12}>
          <Button
            type="primary"
            block
            size='large'
            htmlType="submit"
            onClick={() => crearDiagrama()}
            icon={<SaveOutlined/>}
          >Crear Diagrama</Button>
        </Col>
      </Row>

      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        <Row gutter={[8, 8]}>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 24}}
            lg={{span: 24}}
            xxl={{span: 24}}
          >
            <Form.Item
              label="nombre Area"
              name="nombre"
              disabled
            >
              <Input
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col>
            {
              conceptos.map(concepto =><Row
                key={concepto}
                gutter={16}
                >
                  <Col span={4}> {concepto} </Col>
                  {indicadores?.map(indicador =>
                    <Col
                      key={indicador + "-" + concepto}
                      span={1}
                    >
                      <Form.Item
                        name={indicador + "/" + concepto}
                        dissabled
                      >
                        <Input
                          size='large'
                          autoComplete="off"
                          placeholder = {indicador}
                        />
                      </Form.Item>
                    </Col>
                  )}<br/>
                </Row>
              )
            }
          </Col>

        </Row>


        {/* Boton de guardar  */}
        <Row gutter={10}>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined/>}
              >Guardar</Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </DefaultLayout>
  );
};

export default AreaGantt;
