import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const ActionsButton = ({ options = [] }) => {

  const [ items, setItems ] = React.useState([]);

  React.useEffect(() => {
    if( options?.length > 0 ) {
      const items = [];
      for( let i = 0; i < options.length; i ++ ) {
        items.push({
          key: i +  options[i]?.name,
          icon: options[i].icon,
          label: options[i]?.name,
          onClick: options[i]?.onClick,
          style: options[i]?.styleProps,
          disabled: options[i]?.disabled,
          hidden: options[i]?.hidden,
          danger: options[i]?.danger || false,
        });
      }
      setItems(items);
    }
  }, [options]);

  return (
    <Dropdown
      menu={{ items  }}
      placement="right"
      arrow
    >
      <Button>
        <MoreOutlined />
      </Button>
    </Dropdown>
  );
  
};

ActionsButton.propTypes = {
  options: PropTypes.array.isRequired
};

export default ActionsButton;