import {
  UserOutlined,
  TeamOutlined,
  SettingOutlined,
  ContactsOutlined,
  AppstoreOutlined,
  PartitionOutlined,
  HomeOutlined,
  SlidersOutlined
} from "@ant-design/icons";
import { FaParking } from "react-icons/fa";

import NotAuthorizedView from "../views/shared/NotAuthorized";
import NotFoundView from "../views/shared/NotFound";
import { Perfil } from "../views/perfil";
import { Graficas, Inicio } from "../views/inicio";
import { Areas, AreaForm, AreaGantt } from "../views/gestion-general/area";
import { Cargos, CargoForm } from "../views/gestion-general/cargo";
import { Usuarios, UsuarioForm } from "../views/usuario";
import {
  Permisos,
  PermisosForm,
  PermisosModuloForm,
} from "../views/gestion-general/permisos";
import {
  DiputadosDistrito,
  DiputadosDistritoForm,
} from "../views/gestion-general/diputados-distrito";
import { Md6FtApart, MdHome } from "react-icons/md";
import {
  PartidoPoliticoDetalle,
  PartidoPoliticoListado,
} from "../views/gestion-general/partido-politico";
import {
  FondoLegislativo,
  FondoLegislativoForm,
} from "../views/fondo-legislativo";
import Configuracion from "../views/gestion-general/configuracion/Configuracion";
import ConfiguracionForm from "../views/gestion-general/configuracion/ConfiguracionForm";

const sharedRoutes = [
  {
    path: "/no-autorizado",
    component: NotAuthorizedView,
  },
  {
    path: "*",
    component: NotFoundView,
  },
];

const dashboardRoutes = [
  {
    layout: "dashboard",
    path: "/",
    name: "Inicio",
    icon: <HomeOutlined />,
    sidebar: "single",
    component: Graficas,
  },
  {
    layout: "dashboard",
    path: "/fondo-legislativo",
    name: "Gestión Legislativa",
    icon: <Md6FtApart />,
    sidebar: "single",
    routes: [
      {
        path: "/",
        component: FondoLegislativo,
      },
      {
        path: "/detalle",
        component: FondoLegislativoForm,
      },
    ],
  },
  {
    layout: "dashboard",
    path: "/gestion-general",
    name: "Administración",
    icon: <SettingOutlined />,
    sidebar: "collapse",
    ver: 50,
    rol: ["ADMINISTRADOR", "ADMIN"],
    routes: [
      {
        layout: "dashboard",
        path: "/areas",
        name: "Áreas",
        icon: <PartitionOutlined />,
        ver: 27,
        sidebar: "single",
        routes: [
          {
            path: "/",
            component: Areas,
          },
          {
            path: "/nuevo",
            component: AreaForm,
          },
          {
            path: "/editar",
            component: AreaForm,
          },
          {
            path: "/nuevo-gantt",
            component: AreaGantt,
          },
          {
            path: "/editar-gantt",
            component: AreaGantt,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/cargos",
        name: "Cargos",
        icon: <ContactsOutlined />,
        sidebar: "single",
        ver: 32,
        //rol: ["ADMINISTRADOR"],
        routes: [
          {
            path: "/",
            component: Cargos,
          },
          {
            path: "/nuevo",
            component: CargoForm,
          },
          {
            path: "/editar",
            component: CargoForm,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/diputados-distrito",
        name: "Diputados por Distrito",
        ver: 34,
        //rol: ["ADMINISTRADOR"],
        icon: <UserOutlined />,
        sidebar: "single",
        routes: [
          {
            path: "/",
            component: DiputadosDistrito,
          },
          {
            path: "/nuevo",
            component: DiputadosDistritoForm,
          },
          {
            path: "/editar",
            component: DiputadosDistritoForm,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/partidos-politicos",
        name: "Partidos Politicos",
        ver: 34,
        //rol: ["ADMINISTRADOR"],
        icon: <FaParking />,
        sidebar: "single",
        routes: [
          {
            path: "/",
            component: PartidoPoliticoListado,
          },
          {
            path: "/nuevo",
            component: PartidoPoliticoDetalle,
          },
          {
            path: "/editar",
            component: PartidoPoliticoDetalle,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/permisos",
        name: "Permisos",
        icon: <AppstoreOutlined />,
        sidebar: "single",
        ver: 35,
        rol: ["ADMINISTRADOR"],
        routes: [
          {
            path: "/",
            component: Permisos,
          },
          {
            path: "/nuevo",
            component: PermisosForm,
          },
          {
            path: "/editar",
            component: PermisosForm,
          },
          {
            path: "/modulo-nuevo",
            component: PermisosModuloForm,
          },
          {
            path: "/modulo-editar",
            component: PermisosModuloForm,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/usuarios",
        name: "Usuarios",
        icon: <TeamOutlined />,
        ver: 33,
        sidebar: "single",
        routes: [
          {
            path: "/",
            component: Usuarios,
          },
          {
            path: "/nuevo",
            component: UsuarioForm,
          },
          {
            path: "/editar",
            component: UsuarioForm,
          },
        ],
      },
      {
        layout: "dashboard",
        path: "/configuracion",
        name: "Configuración",
        icon: <SlidersOutlined />,
        ver: 33,
        sidebar: "single",
        routes: [
          {
            path: "/",
            component: Configuracion,
          },
          {
            path: "/nuevo",
            component: ConfiguracionForm,
          },
          {
            path: "/editar",
            component: ConfiguracionForm,
          },
        ],
      },
    ],
  },
  {
    path: "/perfil",
    component: Perfil,
  },
  ...sharedRoutes,
];

const publicRoutes = [...sharedRoutes];

export { dashboardRoutes, publicRoutes };
