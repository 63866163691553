import React, {useState, useMemo, useCallback} from "react";
import { Table, Modal } from 'antd';
import {useHistory} from 'react-router-dom';
import ActionsButton from '../../../components/actionsbutton/ActionsButton';
import SimpleTableLayout from '../../../components/layouts/SimpleTableLayout';

import {
  DeleteOutlined, 
  EditOutlined, 
  ContactsOutlined, 
  PlusOutlined,
  CloseCircleOutlined
} from "@ant-design/icons";

import { useModels } from '../../../hooks';
import StatusResponse from '../../../services/statusResponse';


const Cargos = () => {

  const titulo = "Cargos";
  const url = '/gestion-general/cargos';
  const history = useHistory();
  const [searching, setSearching] = useState(false);
  const [ limite, setLimite ] = useState(10);

  const [
    models,
    modelsLoading,
    // eslint-disable-next-line no-unused-vars
    modelsError,
    modelsPage,
    refreshModels
  ] = useModels({
    name: 'cargo',
    expand: 'area',
    ordenar: 'idCargo-asc',
    limite: limite
  })

  const breadcrumbItems = [
    {
      name: titulo,
      to: url,
      icon: <ContactsOutlined />,
    },
  ];
    
  const buttonData = {
    text: "Agregar nuevo",
    to: () => history.push(url + '/nuevo'),
    icon: <PlusOutlined />,
    props: { disabled: false, type: 'primary', }
  };

  const defaultText = (prop, item) => prop ? <a onClick={()=>history.push(`${url}/editar?id=${item?.idCargo}`)}>
    {prop}</a> : <span style={{color: '#c7c3c3'}}>---</span>;
  
  const columns = [

    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      width: 120,
      align: 'center',
      render: (_, item)=>(
        <ActionsButton
          key={item.id}
          text={"Acción"}
          options={[
            {
              name:'Editar',
              icon: <EditOutlined />,
              onClick: () => history.push(`${url}/editar?id=${item.idCargo}`)
            },
            {
              name: 'Eliminar',
              icon: <DeleteOutlined />,
              onClick: () => modalEliminar(item),
              styleProps: { color: '#ff4d4f' }
            }
          ]}
        />
      )
    },{
      title: '#',
      dataIndex: 'idCargo',
      key: 'idCargo',
      render: defaultText,
    },
    {
      title: 'Nombre',
      dataIndex: 'nombre',
      key: 'nombre',
    },
    {
      title: 'Área',
      dataIndex: 'idArea',
      key: 'idArea',
      render: (_, item) => (item?.area?.nombre),
    },
  ];
    
    const onSearch = async (search) => {
      setSearching(true);
      let params = {};
      if (search.length > 0) params = { ...params, buscar: search};
      params = { ...params, pagina: 0 };
      await refreshModels(true, params);
      if(!modelsLoading) setSearching(false);
    };

    const onSetPageCallback = useCallback(
      async ( page, size ) => {
        setLimite(size);
        await refreshModels ( true, {
          ordenar: 'id-desc',
          pagina: page,
          limite: size,
        })
      }, [ refreshModels ]
    );

      //configuracion de la paginación
  const configPagination = useMemo( ()=> {
    if (modelsPage){
      let size = limite;
      
      return {
        total: modelsPage.total,
        pageSize: modelsPage.limite,
        onShowSizeChange: ( _, newSize)  => ( size = newSize ),
        onChange: async (v) => await onSetPageCallback(v, size),
        showTotal: (total, range) => `${range[0]}-${range[1]} de ${total} cargos.`,
        locale: { items_per_page: '/ página' },
        pageSizeOptions: [10, 20, 30].filter( val => val <= modelsPage.total ),
        showSizeChanger: true
      }
    }return null;
  }, [ onSetPageCallback, limite, modelsPage ]);

  // modal para eliminar
  const modalEliminar = (valor) => {
    Modal.confirm({
      title: "Eliminar",
      icon: <CloseCircleOutlined style={{ color: "red" }} />,
      content: `¿Desea eliminar el área "${valor?.nombre}"?`,
      okText: "Sí",
      cancelText: "No",
      okButtonProps: { danger: true },
      closable: true,
      onOk: async () => {
        const res = await StatusResponse.delete("cargo/eliminar",{ idCargo: valor.idCargo});
        return new Promise((resolve, reject)=>{
          try {
            if (valor.idArea > 0) {
              if (res && res.status === 200) {
                refreshModels(true, {
                  ordenar: 'idCargo-asc',
                  pagina: 1,
                  limite: limite
                });
                resolve();
              } else if ( res?.status === 400 ) {
                Modal.error({
                  title: "Error: no se logro eliminar cargo",
                  content: res?.mensaje,
                });
                reject();
              }
              reject();
            }
          } catch (error) {
            console.log(error);
          }
        })
      },
    });
  };
    
    return (
      <SimpleTableLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
      searchPlaceholder='Buscar...'
      searchLoading={searching}
      onSearchClicked={onSearch}
      emptyText='Aún no hay áreas registrados.'
      children={
        <>
          <Table
            dataSource={models}
            rowKey="idCargo"
            loading={modelsLoading}
            pagination={configPagination}
            columns={columns}
            size='small'
            scroll={{ x: 1000 }}
          />
        </>
      }
    />
  );
}

export default Cargos;