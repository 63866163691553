const { REACT_APP_API_URL: baseUrl } = process.env;
const QuitarObjetosDuplicados = (array, idPrincipal = "idMedia") => {
  let hash = {};
  return array.filter((o) =>
    hash[o[idPrincipal]] ? false : (hash[o[idPrincipal]] = true)
  );
};

export const InformacionArchivos = (archivo, info, setInfo, tipo, token) => {
  if (!archivo) return;
  const _baseUrl = baseUrl.replace("v1/", "");

  let _info = info;

  if (!info.includes(archivo?.idMedia))
    _info.push({
      idMedia: archivo?.idMedia,
      name: archivo?.descripcion,
      uid: archivo?.uuid,
      url: `${_baseUrl}/v1/descarga/documento?uuid=${archivo?.uuid}&access-token=${token}&t=exp`,
      status: "done",
      thumbUrl: `${archivo?.ruta}`,
      tipo: tipo,
    });

  setInfo(QuitarObjetosDuplicados(_info));
};
