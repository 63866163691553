import React from 'react';
import {DefaultLayout} from "../../components/layouts";
import Documento from "./Documento";

const Inicio = () => {

  return (
    <DefaultLayout title="Documentación">
      <Documento/>
    </DefaultLayout>
  );
};

export default Inicio;
