import DefaultLayout from "./DefaultLayout";
import { Input, ConfigProvider, Empty, Col, Row, Button, Space } from "antd";

const { Search } = Input;

const SimpleTableLayout = ({
  breadcrumbItems,
  multipleButtonData,
  buttonData,
  title,
  searchPlaceholder = "Búsqueda",
  searchLoading,
  onSearchClicked,
  children,
  emptyText = "Aún no hay registros.",
  withSearchButton = true,
  searchProps,
}) => {
  return (
    <DefaultLayout breadcrumbItems={breadcrumbItems}>
      {withSearchButton && (
        <Row style={{ marginBottom: 20 }}>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 18, offset: 0 }}
            lg={{ span: 18, offset: 0 }}
            xxl={{ span: 18, offset: 0 }}
            style={{ marginRight: "8%" }}
          >
            <Search
              placeholder={searchPlaceholder}
              enterButton="Buscar"
              size="large"
              style={{ width: "50%", }}
              loading={searchLoading}
              onSearch={onSearchClicked}
              {...searchProps}
            />
          </Col>
          <Col
            xs={{ span: 24, offset: 0 }}
            sm={{ span: 24, offset: 0 }}
            md={{ span: 4, offset: 0 }}
            lg={{ span: 4, offset: 0 }}
            xxl={{ span: 4, offset: 0 }}
          >
            <Space>
              {
                Boolean(buttonData) &&
                  Array.isArray(buttonData) &&
                  buttonData?.length > 0 ? buttonData.map(button => (
                    <Button
                      onClick={button?.to ? button?.to : undefined}
                      {...button?.props}
                      icon={button?.icon}
                      style={{ width: "100%", minHeight: "40px" }}
                    >
                      {button?.text}
                    </Button>
                  )) : (
                  Boolean(buttonData) && (
                      <Button
                        onClick={buttonData?.to ? buttonData?.to : undefined}
                        {...buttonData?.props}
                        icon={buttonData?.icon}
                        style={{ width: "100%", minHeight: "40px" }}
                      >
                        {buttonData?.text}
                      </Button>
                  )
                )
              }
            </Space>
          </Col>

          {multipleButtonData ? (
            <Col style={{ alignContent: "right" }}>
              {multipleButtonData.map((item, index) => (
                <Button
                  key={index}
                  tabIndex={index}
                  style={{ backgroundColor: item.color, color: item.texColor }}
                  onClick={item.to ? item.to : undefined}
                  {...item.props}
                  block
                >
                  {item.icon} {item.text}
                </Button>
              ))}
            </Col>
          ) : null}
        </Row>
      )}

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            style={{ height: 300, paddingTop: "5%" }}
            description={emptyText}
          />
        )}
      >
        {children}
      </ConfigProvider>
    </DefaultLayout>
  );
};

export default SimpleTableLayout;
