import React, {useEffect, useState} from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Form,
  Button,
  message,
  Avatar,
  Divider,
  Tabs,
  Upload, Select,
} from "antd";
import {UserOutlined} from "@ant-design/icons";
import httpService from "../../services/httpService";
import ViewLoading from "../../components/ViewLoading";
import {DefaultLayout} from "../../components/layouts";
import TextArea from "antd/es/input/TextArea";
import {useAuth, useApp} from "../../hooks";
//import {useHistory} from "react-router-dom";
import moment from "moment";

const roles = {
  1: "SOPORTE",
  2: "ADMIN",
  3: "OPERADOR"
};

const Perfil = () => {

  const {REACT_APP_API_URL: baseUrl} = process.env;
  const {user} = useAuth();
  const {TabPane} = Tabs;
  const [form_perfil] = Form.useForm();
  const [form_clave] = Form.useForm()
  const {token} = useApp()
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [ archivoCargando, setArchivoCargando ] = useState(false);
  const [listaArchivos, setListaArchivos] = useState([]);
  const [listaArchivosFotoPerfil, setListaArchivosFotoPerfil] = useState([]);
  const [profile, setProfile] = useState({
    nombre: "",
    apellidoPaterno: "",
    apellidoMaterno: "",
    correo: "",
    rol: "",
    claveActual: "",
    clave: "",
    confirmarClave: "",
    uid: "",
    pin: ""
  });

  const updateProfile = async (values) => {
    if (saving) return;

    try {
      setSaving(true);
      values.idUsuario = user.idUsuario;

      const response = await httpService.post("perfil/guardar", values);
      message.success(response.mensaje);

    } catch (error) {
      console.log(error);
    } finally {
      setSaving(false);
      //window.location.reload(false);
    }
  };

  const updatePassword = async (values) => {
    if (saving) return;

    try {
      setSaving(true);

      if (values.claveActual) {
        if (values.clave !== values.confirmarClave) {
          message.error("Las contraseña no coinciden.");
          return;
        }
      }

      values.idUsuario = user.idUsuario;

      const response = await httpService.post("perfil/cambiar-clave", values);
      //const data = await response.json();
      message.success(response.mensaje);

    } catch (error) {
      console.log(error);
    } finally {

      window.location.reload(false);
      setSaving(false);
    }
  };

  const titulos = [
    {abreviatura: "lic", titulo: "licenciado"},
    {abreviatura: "ing", titulo: "ingeniero"},
    {abreviatura: "mc", titulo: "maestro en ciencias"},
    {abreviatura: "mcs", titulo: "maestro en ciencias sociales"},
    {abreviatura: "doc", titulo: "doctor"},
  ];


  const updatePin = async (values) => {
    if (saving) return;

    try {
      setSaving(true);

      let pinActual = values.pinActual ? values.pinActual : 0
      let pinUser = user.pin ? user.pin : 0

      if(parseInt(pinActual) !== parseInt(pinUser)){
        console.log(typeof values.pinActual, typeof user.pin, parseInt(values.pinActual), parseInt(String(user.pin)), values.pinActual !== String(user.pin))
        message.error("el pin es diferente al guardado.");
        return;
      }

      if (values.pin) {
        if (values.pin !== values.confirmarPin) {
          message.error("No coinciden los datos a guardar.");
          return;
        }
        if(values.pin === '' || values.confirmarPin === ''){
          message.error("Llenar los campos de pin.");
          return;
        }
      }

      values.idUsuario = user.idUsuario;

      const response = await httpService.post("perfil/cambiar-pin", values);
      //const data = await response.json();
      message.success(response.mensaje);

    } catch (error) {
      console.log(error);
    } finally {
      window.location.reload(false);
      setSaving(false);
    }
  };

  const subirImagenPerfil = async (file, idDocumento) => {
    try {
      setArchivoCargando(true);

      let _archivo = file[0]?.originFileObj;

      if (!_archivo) {
        message.info({
          content: 'Debes de seleccionarun archivo',
          style: {marginTop: '20vh'},
        });
        return
      }


      const form = new FormData();
      form.append('archivo', _archivo);
      form.append('idUsuario', user?.idUsuario);


      const response = await fetch(baseUrl + '/v1/perfil/cambiar-foto-perfil', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      });
      console.log(response)
    } catch (error) {
      console.log('error al cargar archivo: ', error);
    } finally {
      setArchivoCargando(false);
      window.location.reload(false);
    }
  };

  const subirFirma = async (file, idDocumento) => {
    try {
      setArchivoCargando(true);

      let _archivo = file[0]?.originFileObj;

      if (!_archivo) {
        message.info({
          content: 'Debes de seleccionarun archivo',
          style: {marginTop: '20vh'},
        });
        return
      }

      const isImage = _archivo.type.includes('image/');

      if (!isImage) {
        message.error(`${_archivo.name} no es un archivo de Imagen`);
      }

      const form = new FormData();
      form.append('archivo', _archivo);
      form.append('idUsuario', user?.idUsuario);

      const response = await fetch(baseUrl + '/v1/perfil/cambiar-firma', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      });
      console.log(response)
    } catch (error) {
      console.log('error al cargar archivo: ', error);
    } finally {
      setArchivoCargando(false);
      window.location.reload(false);
    }
  };

  const onChangeFirma = ({fileList: newFileList}) => {
    let _archivo = newFileList[0]?.originFileObj;
    const isImage = _archivo.type.includes('image/');

    if (!isImage) {
      message.error(`${_archivo.name} no es un archivo de Imagen`);
      return;
    }

    subirFirma(newFileList);

    setListaArchivos(newFileList);
  };

  const onChangePicturePerfil = ({fileList: newFileList}) => {
    let _archivo = newFileList[0]?.originFileObj;
    const isImage = _archivo.type.includes('image/');

    if (!isImage) {
      message.error(`${_archivo.name} no es un archivo de Imagen`);
      return;
    }

    subirImagenPerfil(newFileList);

    setListaArchivosFotoPerfil(newFileList);
  };

  useEffect(() => {
    let mounted = true;

    if (!user || !loading) return;

    const getDataPerfil = async () => {
      try {

        const userData = {
          nombre: user.nombre,
          apellidoPaterno: user.apellidoPaterno,
          apellidoMaterno: user.apellidoMaterno,
          correo: user.correo,
          telefono: user.telefono,
          urlFoto: user.urlFoto,
          numeroEmpleado: user.numeroEmpleado,
          rfc: user.rfc,
          curp: user.curp,
          pin: user.pin ? user.pin : 1234,
          rol: roles[user.rol],
          uid: user.uid,
          extencion: user.extencion,
          claveActual: "",
          clave: "",
          confirmarClave: "",
          titulo: user?.titulo
        };

        if (mounted) setProfile(userData);
        form_perfil.setFieldsValue(userData);

      } catch (error) {
        console.log(error);
      } finally {
        if (mounted) setLoading(false);
      }
    }

    getDataPerfil();

    return () => {
      mounted = false
    }
  }, [form_perfil, loading, user]);

  useEffect(() => {
    let mounted = true;

    if (!user || !loading) return;

    const getDataClave = async () => {
      try {

        const userData = {
          claveActual: "",
          pin: "",
          clave: "",
          confirmarClave: "",
        };

        if (mounted) setProfile(userData);
        form_clave.setFieldsValue(userData);
      } catch (error) {
        console.log(error);
      } finally {
        if (mounted) setLoading(false);
      }
    }

    getDataClave();

    return () => {
      mounted = false
    }
  }, [form_clave, loading, user]);

  if (loading || !user) return <ViewLoading/>;

  return (
    <DefaultLayout
      nameClass={false}
      showBreadcrumb={false}
    >
      <Row gutter={10}>
        <Col
          xs={{span: 24}}
          sm={{span: 24}}
          md={{span: 8}}
          lg={{span: 6}}
        >
          <Card>
            <div style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
              <Upload
                //action={baseUrl + "/reportes/upload"}
                beforeUpload={() => false}
                multiple={true}
                listType="picture-card"
                fileList={listaArchivosFotoPerfil}
                onChange={onChangePicturePerfil}
                style={{width:'100%', height:'100%'}}
              >
                {listaArchivosFotoPerfil.length < 1 && user?.urlFoto ? <Avatar size={100} icon={<UserOutlined/>} src={user?.urlFoto} style={{width:"200px"}}/> : '+ Subir foto' }
              </Upload>
              <label>Elegir foto de Perfil:</label>
              <br/>
              <h3>{user?.nombre} {user?.apellidoPaterno} {user?.apellidoMaterno}</h3>
            </div>
            <Divider/>
            <span><h4>RFC.</h4>{user?.rfc}</span>
            <span><h4>Número de Empleado.</h4>{user?.numeroEmpleado}</span>
            <span><h4>CURP.</h4>{user?.curp}</span>
            <span><h4>Fecha de Ingreso.</h4>{moment(user?.fechaIngreso).format("DD/MM/YYYY")}</span>
            <Divider/>
            <Col
              className="gutter-row"
              span={24}
              style={{display: 'flex', flexDirection: 'column', textAlign: 'center'}}
            >
              <span><h4>Firma actual</h4></span>
              <Upload
                //action={baseUrl + "/reportes/upload"}
                beforeUpload={() => false}
                multiple={true}
                listType="picture-card"
                fileList={listaArchivos}
                onChange={onChangeFirma}
                style={{width:'100%', height:'100%'}}
              >
                {listaArchivos.length < 1 && user?.firma ? <img src={user?.firma} style={{width: '100%'}} alt=""/> : '+ Subir firma'}
              </Upload>
              <label>Elegir firma:</label>
            </Col>
          </Card>
        </Col>
        <Col
          xs={{span: 24}}
          sm={{span: 24}}
          md={{span: 16}}
          lg={{span: 18}}
        >
          <Card>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Actualizar Perfil" key="1" className="">
                <Form
                  autoComplete='off'
                  onFinish={updateProfile}
                  form={form_perfil}
                  layout="vertical"
                >
                  <Row gutter={10}>
                    <Col sm={24} md={8}>
                      <Form.Item
                        name="numeroEmpleado"
                        label="Número de empleado"
                        rules={[{type: "string"}]}
                      >
                        <Input
                          type="string"
                          value={profile.numeroEmpleado}
                          disabled={true}
                          onChange={e => setProfile({...profile, numeroEmpleado: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={8}>
                      <Form.Item
                        name="curp"
                        label="CURP"
                        rules={[{type: "string"}]}
                      >
                        <Input
                          type="string"
                          value={profile.curp}
                          disabled={true}
                          onChange={e => setProfile({...profile, curp: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={8}>
                      <Form.Item
                        name="rfc"
                        label="RFC"
                        rules={[{type: "string"}]}
                      >
                        <Input
                          type="string"
                          value={profile.rfc}
                          disabled={true}
                          onChange={e => setProfile({...profile, rfc: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={3}>
                      <Form.Item
                        name="titulo"
                        label="Título"
                        rules={[{type: "string"}]}
                      >
                        <Input
                          type="text"
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={7}>
                      <Form.Item
                        name="nombre"
                        label="Nombre"
                        rules={[{type: "string"}]}
                      >
                        <Input
                          type="string"
                          disabled={true}
                          value={profile.nombre}
                          onChange={e => setProfile({...profile, nombre: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={7}>
                      <Form.Item
                        name="apellidoPaterno"
                        label="Apellido Paterno"
                        rules={[{type: "string"}]}
                      >
                        <Input
                          type="string"
                          disabled={true}
                          value={profile.apellidoPaterno}
                          onChange={e => setProfile({...profile, apellidoPaterno: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={7}>
                      <Form.Item
                        name="apellidoMaterno"
                        label="Apellido Materno"
                        rules={[{type: "string"}]}
                      >
                        <Input
                          type="string"
                          disabled={true}
                          value={profile.apellidoMaterno}
                          onChange={e => setProfile({...profile, apellidoMaterno: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={8}>
                      <Form.Item
                        name="correo"
                        label="Correo Electrónico"
                        rules={[{type: "email"}]}
                      >
                        <Input
                          type="email"
                          disabled={true}
                          value={profile.correo}
                          onChange={e => setProfile({...profile, correo: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={8}>
                      <Form.Item
                        name="telefono"
                        label="Teléfono"
                      >
                        <Input
                          type="tel"
                          value={profile.telefono}
                          onChange={e => setProfile({...profile, telefono: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={24} md={8}>
                      <Form.Item
                        name="extencion"
                        label="Extención"
                      >
                        <Input
                          type="tel"
                          value={profile.extencion}
                          onChange={e => setProfile({...profile, extencion: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    style={{float: "right"}}
                    type="primary"
                    htmlType="submit"
                    loading={saving}
                  >
                    ACTUALIZAR
                  </Button>
                </Form>
              </TabPane>
              <TabPane tab="Cambiar Contraseña" key="2">
                <Form
                  autoComplete='off'
                  onFinish={updatePassword}
                  form={form_clave}
                  layout="vertical"
                >
                  <Row gutter={10}>
                    <Col sm={24} md={6}>
                      <Form.Item
                        name="claveActual"
                        label="Contraseña Actual"
                      >
                        <Input
                          type="password"
                          value={profile.claveActual}
                          onChange={e => setProfile({...profile, claveActual: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col sm={24} md={6}>
                      <Form.Item
                        name="clave"
                        label="Nueva Contraseña"
                        rules={[
                          {
                            min: 6,
                            message: "La contraseña debe contener al menos 6 caracteres.",
                            type: "string"
                          }
                        ]}
                      >
                        <Input
                          type="password"
                          value={profile.clave}
                          onChange={e => setProfile({...profile, clave: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col sm={24} md={6}>
                      <Form.Item
                        name="confirmarClave"
                        label="Confirmar Contraseña"
                        rules={[
                          {
                            min: 6,
                            message: "La contraseña debe contener al menos 6 caracteres.",
                            type: "string"
                          }
                        ]}
                      >
                        <Input
                          type="password"
                          value={profile.confirmarClave}
                          onChange={e => setProfile({...profile, confirmarClave: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    style={{float: "right"}}
                    type="primary"
                    htmlType="submit"
                    loading={saving}
                  >
                    GUARDAR
                  </Button>
                </Form>
              </TabPane>
              <TabPane tab="Cambiar PIN" key="3">
                <Form
                  autoComplete='off'
                  onFinish={updatePin}
                  form={form_clave}
                  layout="vertical"
                >
                  <Row gutter={10}>
                    <Col
                      sm={24} md={6}
                      hidden={user?.pin === null}
                    >
                      <Form.Item
                        name="pinActual"
                        label="PIN Actual"
                        rules={[
                          {
                            max: 4,
                            message: "La PIN debe contener no mas de 4 digitos.",
                            type: "string"
                          }
                        ]}
                      >
                        <Input
                          type="password"
                          value={profile.pinActual}
                          onChange={e => setProfile({...profile, pinActual: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col sm={24} md={6}>
                      <Form.Item
                        name="pin"
                        label="Nuevo PIN"
                        rules={[
                          {
                            max: 4,
                            message: "La PIN debe contener no mas de 4 digitos.",
                            type: "string"
                          }
                        ]}
                      >
                        <Input
                          type="password"
                          value={profile.pin}
                          onChange={e => setProfile({...profile, pin: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={10}>
                    <Col sm={24} md={6}>
                      <Form.Item
                        name="confirmarPin"
                        label="Confirmar PIN"
                        rules={[
                          {
                            max: 4,
                            message: "La PIN debe contener no mas de 4 digitos.",
                            type: "string"
                          }
                        ]}
                      >
                        <Input
                          type="password"
                          value={profile.confirmarPin}
                          onChange={e => setProfile({...profile, confirmarPin: e.target.value})}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button
                    style={{float: "right"}}
                    type="primary"
                    htmlType="submit"
                    loading={saving}
                  >
                    GUARDAR
                  </Button>
                </Form>
              </TabPane>
            </Tabs>
          </Card>
        </Col>
      </Row>
    </DefaultLayout>
  );
}

export default Perfil;