import React, {useEffect, useMemo, useState} from 'react';
import {
  Col,
  Row,
  Form,
  Input,
  Modal,
  Table,
  Button,
  Select,
  Divider,
  message,
  InputNumber,
} from 'antd';
import {
  SaveOutlined,
  DeleteOutlined,
  FileAddOutlined,
  UserAddOutlined,
  ArrowLeftOutlined,
  PartitionOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import DefaultLayout from '../../../components/layouts/DefaultLayout';
import ActionsButton from "../../../components/actionsbutton";
import {useHistory} from 'react-router-dom';
import StatusResponse from '../../../services/statusResponse';
import {ViewLoading} from '../../../components';
import {useQuery, useModels, useModel, useAuth} from "../../../hooks";
import TextArea from "antd/es/input/TextArea";


const AreaForm = () => {

  const {Option} = Select;
  const titulo = 'Áreas';
  const [form] = Form.useForm();
  const history = useHistory();

  const query = useQuery();
  const id = query.get("id");
  const editing = !!id;

  const {user} = useAuth();

  const [loading, setLoading] = useState(false);
  const [idsUsuariosArea, setIdsUsuariosArea] = useState([]);
  const [usuariosTabla, setUsuariosTabla] = useState([]);

  const idAreaUsuariosMemo = useMemo(() => ({idArea: id}), [id]);

  // Adscripcion
  const {
    model, modelLoading,
  } = useModel({
    name: 'area',
    id: id,
    expand: 'fondo,usuarios'
  });

  // Fondos
  const [
    fondos, fondosLoading,
  ] = useModels({
    name: 'fondo',
    ordenar: 'idFondo-desc',
    limit: 10,
  });

  // Adscripcion
  const [
    adscripcion, adscripcionLoading,
  ] = useModels({
    name: 'adscripcion',
    ordenar: 'idAdscripcion-desc',
    limit: 10,
  });

  // Usuarios
  const [
    usuarios,
    usuariosLoading, , ,
    UsuariosRefresh
  ] = useModels({
    name: 'usuario',
  });

  const columns = [
    {
      title: '',
      dataIndex: 'Acciones',
      key: 'Acciones',
      align: 'center',
      render: (_, usuario) => (
        <ActionsButton
          key={usuario.idUsuario}
          text={"Acción"}
          options={[
            {
              name: 'Remover',
              icon: <DeleteOutlined/>,
              onClick: () => modalEliminar(usuario),
              styleProps: {color: '#ff5722'}
            }
          ]}
        />
      )
    },
    {
      title: 'Usuario',
      dataIndex: 'nombre',
      key: 'nombre',
      render: (_, item) => {
        const usuario = usuariosTabla.filter(usr => usr.idUsuario === item.idUsuario)[0];
        return usuario?.nombre + " " + usuario?.apellidoPaterno + " " + usuario?.apellidoMaterno;
      }
    },
    {
      title: 'Teléfono',
      dataIndex: 'telefono',
      key: 'telefono',
      render: (_, item) => {
        const usuario = usuariosTabla.filter(usr => usr.idUsuario === item.idUsuario)[0];
        return usuario?.telefono;
      },
    },
  ];

  const breadcrumbItems = [
    {
      name: "Áreas",
      to: '/gestion-general/areas/',
      icon: <PartitionOutlined/>,
    },
    {
      name: editing ? "Área" : "Nuevo",
      to: editing ? '/gestion-general/areas/editar?id=' + id : '/gestion-general/areas/nuevo',
      icon: <FileAddOutlined/>,
    },
  ];

  const buttonData = {
    text: "Volver",
    to: () => history.push('/gestion-general/areas/'),
    icon: <ArrowLeftOutlined/>,
    props: {disabled: false, type: 'primary',}
  };

  const agregarUsuarios = async () => {

    // Validar si selecciono un unsuario
    if (idsUsuariosArea.length === 0) {
      message.info({
        content: 'Debes de seleccionar al menos un usuario',
        style: {marginTop: '14vh'}
      });
      return
    }

    const _usuariosTabla = [...usuariosTabla];

    for (let i = 0; i < idsUsuariosArea.length; i++) {
      const encontrado = usuarios.find(u => u.idUsuario === idsUsuariosArea[i]);
      _usuariosTabla.push(encontrado)
    }

    const resultUsuarios = _usuariosTabla.reduce((unico, o) => {
      if (!unico.some(obj => obj.idUsuario === o.idUsuario)) {
        unico.push(o)
      }
      return unico
    }, []);

    setUsuariosTabla(resultUsuarios)
    setIdsUsuariosArea([])
    form.setFieldsValue({
      usuarios: []
    });

  };

  const modalEliminar = (usuario) => {

    Modal.confirm({
      title: "Remover",
      icon: <CloseCircleOutlined style={{color: "#ff5722"}}/>,
      content: `¿Desea remover del área el usuario '${usuario?.nombre} ${usuario?.apellidoPaterno} ${usuario?.apellidoMaterno}' ?`,
      okText: "Sí",
      cancelText: "Cancelar",
      okButtonProps: {danger: true},
      closable: true,
      onOk: async () => {

        let res = null
        if (editing) {
          res = await StatusResponse.delete('/v1/area-usuario', {idArea: id, idUsuario: usuario?.idUsuario});
        }

        let _usuariosTabla = [...usuariosTabla];
        const index = _usuariosTabla.findIndex(u => u.idUsuario === usuario.idUsuario)
        if (index > -1) {
          _usuariosTabla.splice(index, 1)
        }
        setUsuariosTabla(_usuariosTabla)
        try {
          if (usuario?.idUsuario > 0) {
            if (res && res?.status === 200) {
              Modal.success({
                title: 'Eliminado',
                content: res?.mensaje,
              });
            }
          } else if (res?.status === 400) {
            Modal.error({
              title: 'Error: no se logro eliminar el usuario',
              content: res?.mensaje,
            });
          }
        } catch (error) {
          console.log('error al eliminar usuario: ', error)
        }

      },
    });
  };

  const buscarUsuarios = async (value) => {
    if (value?.length > 0) {
      await UsuariosRefresh(true, {
        buscar: value
      })
    }
  };

  const onFinish = async (values) => {

    const {
      nombre,
      codigo,
      secuencialFolioDocumento,
      secuencialFolioSolicitudPOA,
      secuencialFolioExpediente,
      secuencialFolioRequisicion,
      aumentoFolioDocumento,
      aumentoFolioSolicitudPOA,
      aumentoFolioExpediente,
      aumentoFolioRequisicion,
      diaAumento,
      idFondo,
      mision,
      vision,
      diagnostico,
      objetivo,
      idAdscripcion,
      idUsuarioTitular,
    } = values;

    let body = {
      nombre,
      codigo,
      secuencialFolioDocumento,
      secuencialFolioSolicitudPOA,
      secuencialFolioExpediente,
      secuencialFolioRequisicion,
      aumentoFolioDocumento,
      aumentoFolioSolicitudPOA,
      aumentoFolioExpediente,
      aumentoFolioRequisicion,
      diaAumento,
      idFondo,
      mision,
      vision,
      diagnostico,
      objetivo,
      idAdscripcion,
      idUsuarioTitular,
    }
    if (editing) {
      body.idArea = id;
    }
    try {
      setLoading(true)

      const idUsuarios = usuariosTabla.map(u => {
        return u.idUsuario
      })
      body.usuarioArea = idUsuarios

      const res = await StatusResponse.post('area/guardar', body);

      if (res && res?.status === 200) {
        Modal.success({
          title: 'Guardado',
          content: res?.mensaje,
        });
      }

      history.push('/gestion-general/areas')


    } catch (e) {
      console.log('Error al guardar: ', e);
    } finally {
      setLoading(false)
    }
  };

  const onFinishFailed = ({values, errorFields, outOfDate}) => {
    message.warning('Error al guardar: datos incompletos.');
    console.log(values, errorFields, outOfDate);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && model && !modelLoading) {
      setUsuariosTabla(model?.usuarios)
      form.setFieldsValue({
        ...model,
        nombre: model?.nombre,
        idArea: model?.idCargo,
        usuarios: []
      })
    }
    return () => mounted = false;
  }, [form, modelLoading, model]);

  if (modelLoading) return <ViewLoading/>;

  return (
    <DefaultLayout
      title={titulo}
      breadcrumbItems={breadcrumbItems}
      buttonData={buttonData}
    >

      <Form
        form={form}
        name="form"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >

        {/* Código del área | Nombre del área | Fondo  */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 4}}
            lg={{span: 4}}
            xxl={{span: 4}}
          >
            <Form.Item
              label="Código del Área"
              name="codigo"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                size='large'
                placeholder="Código del Área"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 6}}
            lg={{span: 6}}
            xxl={{span: 6}}
          >
            <Form.Item
              label="Nombre del Área"
              name="nombre"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <Input
                size='large'
                placeholder="Nombre del Área"
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 7}}
            lg={{span: 7}}
          >
            <Form.Item
              label="Fondo"
              name="idFondo"
              rules={[{required: true, message: 'Requerido'}]}
              hasFeedback
            >
              <Select
                size='large'
                loading={fondosLoading}
                disabled={fondosLoading}
                placeholder="Selecciona una institución"

              >
                {fondos.map((item, index) => (
                  <Option key={index} value={item.idFondo}>{item.titulo}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col
            className='gutter-row'
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 7}}
            lg={{span: 7}}
          >
            <Form.Item
              label="Adscripción"
              name="idAdscripcion"
              hasFeedback
            >
              <Select
                size='large'
                loading={adscripcionLoading}
                disabled={adscripcionLoading}
                placeholder="Selecciona una Adscripción"
                options={adscripcion && adscripcion.map(item => ({
                  value: item.idAdscripcion,
                  label: item?.nombre
                }))}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Folio documentos | folio expendiente  */}
        <Row gutter={10}>
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item
              label="Folio documentos"
              name="secuencialFolioDocumento"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item
              label="Folio Solicitudes POA"
              name="secuencialFolioSolicitudPOA"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item
              label="Folio expedientes"
              name="secuencialFolioExpediente"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item
              label="Folio de FondoLegislativo"
              name="secuencialFolioRequisicion"
              rules={[{required: true, message: 'Requerido'}]}
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row
          //hidden={!user?.permisoExtra.includes(48)}
          gutter={8}
        >
          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item
              label="Aumento de Folio documentos"
              name="aumentoFolioDocumento"
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item
              label="Aumento de Folio Solicitudes POA"
              name="aumentoFolioSolicitudPOA"
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item
              label="Aumento de Folio expedientes"
              name="aumentoFolioExpediente"
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item
              label="Aumento de Folio de FondoLegislativo"
              name="aumentoFolioRequisicion"
            >
              <InputNumber
                min={0}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 4}}
            lg={{span: 4}}
            xxl={{span: 4}}
          >
            <Form.Item
              label="Dia de cambio"
              name="diaAumento"
            >
              <InputNumber
                min={0}
                max={29}
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

        </Row>

        <Row gutter={10}>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Misión"
              name="mision"
            >
              <TextArea
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Visión"
              name="vision"
            >
              <TextArea
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Diagnostico"
              name="diagnostico"
            >
              <TextArea
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>

          <Col
            className="gutter-row"
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 12}}
            lg={{span: 12}}
            xxl={{span: 12}}
          >
            <Form.Item
              label="Objetivo"
              name="objetivo"
            >
              <TextArea
                style={{width: '100%'}}
                size='large'
                autoComplete="off"
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={10}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label="Responsable del Área"
              name="idUsuarioTitular"
            >
              <Select
                size='large'
              >
                {model?.usuarios?.map(item => <Option key={item?.idUsuario} value={item?.idUsuario}>
                    {item?.nombre} {item?.apellidoPaterno} {item?.apellidoMaterno}
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        {/* Selector de usuarios  */}
        <Row gutter={10}>

          <Col className="gutter-row" span={18}>
            <Form.Item
              label="Usuarios"
              name="usuarios"
            >
              <Select
                allowClear
                showSearch
                mode="multiple"
                size='large'
                optionFilterProp="children"
                loading={usuariosLoading}
                onSearch={buscarUsuarios}
                style={{width: '100%'}}
                placeholder="Selecciona"
                onChange={(id) => {
                  setIdsUsuariosArea(id);
                }}
              >
                {usuarios?.map(item => (
                  <Option key={item.idUsuario} value={item.idUsuario}>
                    {item.nombre} {item.apellidoPaterno} {item.apellidoMaterno}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col className="gutter-row" span={6}>
            <Form.Item label="&nbsp;">
              <Button
                type="primary"
                block
                size='large'
                icon={<UserAddOutlined/>}
                onClick={agregarUsuarios}
              >Agregar</Button>
            </Form.Item>
          </Col>
        </Row>

        {/* Tabla usuarios */}
        <Row gutter={10}>
          <Col className="gutter-row" span={24}>
            <Table
              dataSource={usuariosTabla}
              rowKey="idUsuario"
              columns={columns}
              size='small'
            />
          </Col>
        </Row>

        <Divider/>

        {/* Boton de guardar  */}
        <Row gutter={10}>
          <Col
            xs={{span: 24}}
            sm={{span: 24}}
            md={{span: 5}}
            lg={{span: 5}}
            xxl={{span: 5}}
          >
            <Form.Item>
              <Button
                type="primary"
                block
                size='large'
                htmlType="submit"
                loading={loading}
                icon={<SaveOutlined/>}
              >Guardar</Button>
            </Form.Item>
          </Col>
        </Row>

      </Form>
    </DefaultLayout>
  );
};

export default AreaForm;
