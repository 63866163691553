import React, { useMemo, useState, useEffect } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Space,
  Tooltip,
  Button,
  Tabs,
  Card,
  DatePicker,
  Statistic,
  Tag,
  Select as AntdSelect,
} from "antd";
import {
  EditOutlined,
  PlusOutlined,
  FileDoneOutlined,
  SearchOutlined,
  ClearOutlined,
  PrinterOutlined,
  FileExcelOutlined,
  ReconciliationOutlined,
  HistoryOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { AiFillFolderOpen } from "react-icons/ai";
import { useApp, useAuth, useModels, useQuery } from "../../hooks";
import { ActionsButton, Select } from "../../components";
import { SimpleTableLayout } from "../../components/layouts";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { emptyRequest } from "../../constants/requests";
import Tabla from "../../components/Tabla";
import { Anios } from "../../utilities";

const { TabPane } = Tabs;
const { REACT_APP_API_URL: baseUrl } = process.env;

const mesesGrid = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10: "Octubre",
  11: "Noviembre",
  12: "Diciembre",
};

const estatusAprobador = [
  "",
  "NUEVO",
  "NUEVO",
  "VALIDADO",
  "APROBADO",
  "RECHAZADO",
];

const estatusDiputado = ["", "NUEVO", "VALIDADO", "APROBADO", "RECHAZADO"];

const diputado = { name: "usuario", ordenar: "nombre-desc" };
const diputadoFiltro = { diputados: "true" };

const FondoLegislativo = () => {
  const titulo = "Gestión Legislativa";
  const history = useHistory();
  const { token } = useApp();
  const { user } = useAuth();
  const query = useQuery();
  const opt = query.get("opt");
  const t = query.get("t");
  const model = "fondo-legislativo";
  const ordenar = "creado-desc";

  const [tab, setTab] = useState(t || "1");
  const [buscar, setBuscar] = useState(undefined);
  const [inicio, setInicio] = useState(undefined);
  const [esAprobador, setEsAprobador] = useState(false);
  const [filtroDiputado, setFiltroDiputado] = useState("");
  const [filtroMes, setFiltroMes] = useState("");
  const [filtroAnio, setFiltroAnio] = useState("");

  const { RangePicker } = DatePicker;
  const [fin, setFin] = useState(undefined);
  const _baseUrl = baseUrl.replace("v1/", "");
  const [form] = Form.useForm();

  const [dashboardRequest, setDashboardRequest] = useState(emptyRequest);

  let esValidador = user?.permisoExtra?.includes(165);

  const extraParamsMemo = useMemo(() => {
    let params = esAprobador
      ? { estatus: estatusAprobador[tab - 1] }
      : { estatus: estatusDiputado[tab - 1] };

    if (tab === "2" && esAprobador) params.tipo = "FGL";
    if (tab === "3" && esAprobador) params.tipo = "51";

    if (buscar && buscar.trim() !== "") {
      params.buscar = buscar.trim();
    }

    if (inicio && fin) {
      params.inicio = inicio.format("YYYY-MM-DD");
      params.fin = fin.format("YYYY-MM-DD");
    } else {
      params.inicio = "";
      params.fin = "";
    }

    if (filtroDiputado !== "") {
      params.idUsuario = filtroDiputado;
    }

    if (filtroMes !== "") {
      params.mes = filtroMes;
    }

    if (filtroAnio) {
      params.anio = filtroAnio;
    }

    if (opt) {
      params.opt = opt;
    }

    return params;
  }, [
    buscar,
    fin,
    inicio,
    tab,
    opt,
    esAprobador,
    filtroDiputado,
    filtroMes,
    filtroAnio,
  ]);

  const paramsDashboard = useMemo(
    () => ({
      name: model + "/dashboard",
      extraParams: extraParamsMemo,
    }),
    [extraParamsMemo]
  );

  useEffect(() => {
    setDashboardRequest(paramsDashboard);
    return () => setDashboardRequest(emptyRequest);
  }, [paramsDashboard]);

  const [dashboard] = useModels(dashboardRequest);

  const tagColores = {
    VALIDADO: "#03aef6",
    APROBADO: "#006843",
    RECHAZADO: "#f00821",
  };

  const defaultMonto = (prop, item) => {
    return item ? (
      <span
        className={"link-pointer"}
        onClick={() => {
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
        }}
      >
        $
        {new Intl.NumberFormat("es-MX", { minimumFractionDigits: 2 }).format(
          prop
        )}
      </span>
    ) : (
      <span style={{ color: "#c7c3c3" }}>---</span>
    );
  };

  const defaultTipo = (prop, item) => {
    return item ? (
      <span
        className={"link-pointer"}
        onClick={() => {
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
        }}
      >
        {prop === "FGL"
          ? "Asistencia Legislativa y Atención Ciudadana"
          : "Representación Legislativa"}
      </span>
    ) : (
      <span style={{ color: "#c7c3c3" }}>---</span>
    );
  };

  const defaultSubTipo = (prop, item) => {
    let _props = prop?.split(",");

    let mensaje = _props?.map(
      (item) => item.charAt(0) + item.toLowerCase().slice(1) + " "
    );
    return item ? (
      <span
        className={"link-pointer"}
        onClick={() => {
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
        }}
      >
        {mensaje}
      </span>
    ) : (
      <span style={{ color: "#c7c3c3" }}>---</span>
    );
  };

  const defaultCreador = (prop, item) => {
    return item ? (
      <span
        className={"link-pointer"}
        onClick={() => {
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
        }}
      >
        {item?.creador?.nombreCompleto}
      </span>
    ) : (
      <span style={{ color: "#c7c3c3" }}>---</span>
    );
  };

  const optionsButtons = (item) => {
    let _mostrar = false;

    if (user?.permisoExtra?.includes(155)) _mostrar = true;
    if (user?.permisoExtra?.includes(156)) _mostrar = true;
    if (item?.estatus !== "APROBADO") _mostrar = false;

    return [
      {
        name: "Detalles",
        icon: <EditOutlined />,
        onClick: () =>
          history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`),
      },
      {
        name: "Formato Solicitud",
        icon: <PrinterOutlined />,
        onClick: () =>
          window.open(
            `${_baseUrl}/pdf/${model}/por-autorizar?id=${item.idFondoLegislativo}&access-token=${token}`,
            "_blank"
          ),
      },
      _mostrar
        ? {
            name: "Formato Aprobación",
            icon: <PrinterOutlined />,
            onClick: () =>
              window.open(
                `${_baseUrl}/pdf/${model}/autorizacion?id=${item.idFondoLegislativo}&access-token=${token}`,
                "_blank"
              ),
          }
        : {},
    ];
  };

  const columnas = [
    {
      title: "",
      dataIndex: "Acciones",
      key: "Acciones",
      align: "center",
      render: (_, item) => (
        <ActionsButton
          key={item.id}
          text={""}
          size="large"
          type="default"
          options={optionsButtons(item)}
        />
      ),
    },
    {
      title: "Fecha",
      dataIndex: "creado",
      key: "creado",
      render: (_, item) => (
        <span
          onClick={() => {
            history.push(`/${model}/detalle?id=${item.idFondoLegislativo}`);
          }}
          className={"link-pointer"}
        >
          {moment(item.creado).parseZone().local().format("DD/MM/YYYY HH:mm")}
        </span>
      ),
    },
    user?.permisoExtra?.includes(155) ||
    user?.permisoExtra?.includes(156) ||
    user?.permisoExtra?.includes(165)
      ? {
          title: "Diputado",
          dataIndex: "idCreador",
          key: "idCreador",
          render: defaultCreador,
        }
      : {},
    {
      title: "Folio",
      dataIndex: "folio",
      key: "folio",
      render: (item, row) => (
        <Tag
          color={tagColores[row?.estatus]}
          className={"link-pointer"}
          onClick={() => {
            history.push(`/${model}/detalle?id=${row?.idFondoLegislativo}`);
          }}
        >
          {item}
        </Tag>
      ),
    },
    {
      title: "Mes Solicitado",
      dataIndex: "mes",
      key: "mes",
      render: (item, row) => (
        <span
          className={"link-pointer"}
          onClick={() => {
            history.push(`/${model}/detalle?id=${row?.idFondoLegislativo}`);
          }}
        >
          {mesesGrid[item]}
        </span>
      ),
    },
    {
      title: "Concepto",
      dataIndex: "tipo",
      key: "tipo",
      render: defaultTipo,
    },
    {
      title: "Tipo",
      dataIndex: "subTipo",
      key: "subTipo",
      render: defaultSubTipo,
    },
    {
      title: "Monto",
      dataIndex: "monto",
      key: "monto",
      render: defaultMonto,
    },
  ];

  const breadcrumbItems = [
    {
      name: titulo,
      to: "/fondo-legislativo",
      icon: <FileDoneOutlined />,
    },
  ];

  const onFinishButton = async (values) => {
    const { buscar, rango, mes, idUsuario, anio } = form.getFieldsValue(true);
    setBuscar("");
    setInicio(null);
    setFin(null);

    if (mes) {
      setFiltroMes(mes);
    }

    if (idUsuario) {
      setFiltroDiputado(idUsuario);
    }

    if (anio) {
      setFiltroAnio(anio);
    }

    if (buscar && buscar.trim() !== "") {
      setBuscar(buscar.trim());
    }
    if (rango && rango.length > 0) {
      setInicio(moment(rango[0].format("YYYY-MM-DD") + " 00:00:00"));
      setFin(moment(rango[1].format("YYYY-MM-DD") + " 23:59:59"));
    }
  };

  const tabsHeader = [
    {
      tab: (
        <span>
          <ReconciliationOutlined />
          Principal
        </span>
      ),
      key: "1",
    },
    {
      tab: (
        <span>
          {" "}
          <HistoryOutlined />
          En Proceso ({dashboard[0]?.nuevos})
        </span>
      ),
      key: "2",
    },
    {
      tab: (
        <span>
          {" "}
          <HistoryOutlined />
          Verificados ({dashboard[0]?.validados})
        </span>
      ),
      key: "3",
    },
    {
      tab: (
        <span>
          {" "}
          <CheckCircleOutlined />
          Autorizados ({dashboard[0]?.aprobados})
        </span>
      ),
      key: "4",
    },
    {
      tab: (
        <span>
          {" "}
          <CloseCircleOutlined />
          Rechazados ({dashboard[0]?.rechazados})
        </span>
      ),
      key: "5",
    },
  ];

  const tabsHeaderPermisos = [
    {
      tab: (
        <span>
          <ReconciliationOutlined />
          Principal
        </span>
      ),
      key: "1",
    },
    {
      tab: (
        <span>
          {" "}
          <HistoryOutlined />
          Asistencia Legislativa y Atención Ciudadana ({dashboard[0]?.fgl})
        </span>
      ),
      key: "2",
    },
    {
      tab: (
        <span>
          {" "}
          <HistoryOutlined />
          Representación Legislativa ({dashboard[0]?.dga})
        </span>
      ),
      key: "3",
    },
    {
      tab: (
        <span>
          {" "}
          <CheckCircleOutlined />
          Verificados ({dashboard[0]?.validados})
        </span>
      ),
      key: "4",
    },
    {
      tab: (
        <span>
          {" "}
          <CheckCircleOutlined />
          Autorizados ({dashboard[0]?.aprobados})
        </span>
      ),
      key: "5",
    },
    {
      tab: (
        <span>
          {" "}
          <CloseCircleOutlined />
          Rechazados ({dashboard[0]?.rechazados})
        </span>
      ),
      key: "6",
    },
  ];

  const onPrintExcel = () => {
    const { rango, docTipo } = form.getFieldsValue(true);

    if (rango === undefined) {
      Modal.error({
        title: "elegir un rango de fechas",
        content: "Seleccione un rango de fechas",
      });
      return;
    }

    window.open(
      `${_baseUrl}/pdf/reportes/excel-${model}?` +
        `fi=${rango[0].format("YYYY-MM-DD")}&` +
        `ff=${rango[1].format("YYYY-MM-DD")}&` +
        (docTipo ? `t=${docTipo}&` : "") +
        `access-token=${token}`,
      "_blank"
    );
  };

  useEffect(() => {
    if (user?.permisoExtra?.includes(165)) setEsAprobador(true);
    if (user?.permisoExtra?.includes(156)) setEsAprobador(true);
    if (user?.permisoExtra?.includes(155)) setEsAprobador(true);
  }, [user]);

  return (
    <SimpleTableLayout
      title={titulo}
      withSearchButton={false}
      breadcrumbItems={breadcrumbItems}
      children={
        <>
          <Row gutter={3} justify="space-between">
            <Col flex={1}>
              <Card
                className="link-pointer"
                onClick={() => {
                  setTab("2");
                }}
              >
                <Statistic
                  title="En Proceso"
                  value={dashboard[0]?.nuevos}
                  prefix={<HistoryOutlined />}
                />
              </Card>
            </Col>
            <Col flex={1}>
              <Card
                className="link-pointer"
                onClick={() => {
                  esAprobador && esValidador ? setTab("4") : setTab("3");
                }}
              >
                <Statistic
                  title="Verificados"
                  valueStyle={{
                    color: "#03a9f4",
                  }}
                  value={dashboard[0]?.validados}
                  prefix={<CheckCircleOutlined />}
                />
              </Card>
            </Col>
            <Col flex={1}>
              <Card
                className="link-pointer"
                onClick={() => {
                  esAprobador && esValidador ? setTab("5") : setTab("4");
                }}
              >
                <Statistic
                  title="Autorizados"
                  valueStyle={{
                    color: "#1c5401",
                  }}
                  value={dashboard[0]?.aprobados}
                  prefix={<CheckCircleOutlined />}
                />
              </Card>
            </Col>
            <Col flex={1}>
              <Card
                className="link-pointer"
                onClick={() => {
                  esAprobador && esValidador ? setTab("6") : setTab("5");
                }}
              >
                <Statistic
                  title="Rechazados"
                  valueStyle={{
                    color: "#ce0000",
                  }}
                  value={dashboard[0]?.rechazados}
                  prefix={<CloseCircleOutlined />}
                />
              </Card>
            </Col>
            <Col flex={1}>
              <Card>
                <Statistic
                  title="Total"
                  value={dashboard[0]?.todos}
                  prefix={<AiFillFolderOpen />}
                />
              </Card>
            </Col>
          </Row>
          <Form form={form} layout="vertical">
            <Row gutter={[10, 10]}>
              <Col span={24} md={4}>
                <Form.Item label="Rango:" name="rango">
                  <RangePicker
                    allowClear
                    onChange={() => {
                      setInicio("");
                      setFin("");
                    }}
                    format={["DD/MM/YYYY", "DD/MM/YYYY"]}
                    placeholder={["FECHA INICIO", "FECHA LÍMITE"]}
                    style={{ width: "100%" }}
                    defaultValue={[inicio, fin]}
                    value={[inicio, fin]}
                  />
                </Form.Item>
              </Col>
              {(esAprobador || esValidador) && (
                <Col span={4}>
                  <Form.Item name={"idUsuario"} label="Diputado">
                    <Select
                      placeholder="Filtrar por Diputado"
                      modelsParams={diputado}
                      labelProp={"nombre"}
                      valueProp={"idUsuario"}
                      extraParams={diputadoFiltro}
                      onClear={() => setFiltroDiputado("")}
                    />
                  </Form.Item>
                </Col>
              )}
              <Col span={4}>
                <Form.Item name={"mes"} label="Mes">
                  <AntdSelect
                    onClear={() => setFiltroMes("")}
                    allowClear
                    placeholder="Filtrar por mes"
                    options={Object.keys(mesesGrid).map((value) => ({
                      value: value,
                      label: mesesGrid[value],
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col span={4}>
                <Form.Item name={"anio"} label="Año">
                  <AntdSelect
                    onClear={() => setFiltroAnio("")}
                    allowClear
                    autoComplete="off"
                    style={{ width: "100%" }}
                    placeholder="Filrar por año"
                    options={Anios().map((item) => ({
                      key: item.value,
                      value: item.value,
                      label: item.label,
                    }))}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Space justify="space-between" style={{ marginTop: ".5rem" }}>
                  <Tooltip title="Buscar">
                    <Button
                      icon={<SearchOutlined />}
                      style={{ marginTop: "1.5rem" }}
                      shape="circle"
                      type="primary"
                      onClick={onFinishButton}
                    />
                  </Tooltip>

                  <Tooltip title="Limpiar">
                    <Button
                      icon={<ClearOutlined />}
                      style={{ marginTop: "1.5rem" }}
                      shape="circle"
                      type="default"
                      onClick={() => {
                        form.resetFields();
                        setBuscar("");
                        setInicio(null);
                        setFin(null);
                        setFiltroDiputado("");
                        setFiltroMes("");
                        setFiltroAnio("");
                        if (t && opt) {
                          window.history.replaceState("", "", "/requisiciones");
                          window.location.reload();
                        }
                      }}
                    />
                  </Tooltip>

                  <Tooltip title="Imprimir Excel">
                    <Button
                      icon={<FileExcelOutlined />}
                      shape="circle"
                      onClick={onPrintExcel}
                      style={{
                        marginTop: "1.5rem",
                        background: "#1f6e43",
                        color: "#ffffff",
                      }}
                    />
                  </Tooltip>
                </Space>
              </Col>
              <Col
                span={24}
                md={{ offset: esValidador || esAprobador ? 2 : 4, span: 4 }}
              >
                <Form.Item label="&nbsp;">
                  <Button
                    block
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => history.push(`/fondo-legislativo/detalle`)}
                  >
                    Agregar
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Tabs
            defaultActiveKey={{ tab } + ""}
            activeKey={tab}
            type="card"
            onChange={(e) => {
              setTab(e);
            }}
          >
            {(esAprobador ? tabsHeaderPermisos : tabsHeader).map((tap) => (
              <TabPane tab={tap.tab} key={tap.key} />
            ))}
          </Tabs>
          <Row gutter={[3, 5]}>
            <Col span={24}>
              <Tabla
                nameURL="fondo-legislativo"
                expand="creador, modificador, aprueba, archivos, comentarios, comentarios.usuario, solicitudes"
                extraParams={extraParamsMemo}
                columns={columnas}
                order={ordenar}
                size="small"
                scroll={{ x: 1000 }}
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </>
      }
    />
  );
};

export default FondoLegislativo;
